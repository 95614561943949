import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {BbsData, FileData} from "@/network/apis/types";

/**
 * 공지사항 상세
 * @param params
 * @param config
 */
export function getUserNoticeDetail(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        bbsNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/notice/detail.do', {
        ...restConfig,
        params: {
            bbsNo,
            searchBbsCd: 'A201',
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 공지사항 번호
 */
interface Params {
    bbsNo: number;
}


interface ResponseData {
    result: {
        detail: BbsData;
        next: BbsData;
        prev: BbsData;
        files: FileData[];
    }
}

