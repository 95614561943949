import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserEducationSurveyAnswer} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {ParamEducationSurveyAnswer} from "@/network/apis/types";

interface ResponseData {
    result: number;
}

export function useRegistUserEducationSurvey() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: ParamEducationSurveyAnswer) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                postUserEducationSurveyAnswer(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        const {result} = data
                        if(result >= 0){
                            resolve({result})
                            alert('설문을 완료하였습니다.')
                        }else{
                            alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                            reject()
                        }
                    })
                    .catch(() => {
                        alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                        reject()
                    })
            )
        })

    }, [prevCanceler, showGlobalLoading])
}