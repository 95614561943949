import React, {ChangeEvent, Dispatch, PropsWithChildren, SetStateAction, useCallback, useMemo} from 'react'

interface Props<P extends string> extends PropsWithChildren<{}> {
    defaultValue: P | '';
    className?: string;
    items?: Record<P, string>
    setValue: Dispatch<SetStateAction<P | ''>>;
    disabled?: boolean;
    onChange?: () => void;
    showAll?: boolean;
    showAllTitle?: string;
}

function CommonOption<P extends string>(props: Props<P>) {
    const {defaultValue, className, items, setValue, disabled, onChange, showAll = false, showAllTitle, children} = props
    const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value as P)
        onChange?.()
    }, [setValue, onChange])

    const entriesItems = useMemo(() => {
        if(items) return Object.entries(items)
        return []
    }, [items])

    return (
        <>
            <select className={className} value={String(defaultValue)} disabled={disabled} onChange={handleChange}>
                {
                    showAll && (
                        <option value="">{showAllTitle ? showAllTitle : '전체'}</option>
                    )
                }
                {
                    entriesItems.map(([key, value]) => {
                        const keyStr = `common-option-item-${key}`
                        return (
                            <option key={keyStr} value={key}>{String(value)}</option>
                        )
                    })
                }
                {
                    children
                }
            </select>
        </>
    )
}

export { CommonOption }