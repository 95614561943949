import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {GrpCd} from "@/network/apis/types";

/**
 * 공통코드
 * @param params
 * @param config
 */
export function getCommonCode(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/common/code/list.do', {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 X
 */
interface Params {
}


interface ResponseData {
    list: {[Key in GrpCd]: CommonCode[]};
}

/**
 * commCd:          코드
 * commNm:          코드명
 * grpCd:           부모코드
 */
export interface CommonCode {
    commCd: string;
    commNm: string;
    grpCd: string;
}
