import styled, { AnyStyledComponent } from 'styled-components'

export const typeSmall = (c: AnyStyledComponent) => styled(c)`
  font-size: 1.3rem;
`

export const typeXsmall = (c: AnyStyledComponent) => styled(c)`
  font-size: 1.2rem;
`

export const typeBreakAll = (c: AnyStyledComponent) => styled(c)`
  word-break: break-all;
`

export const typePreserveWrap = (c: AnyStyledComponent) => styled(c)`
  white-space: pre-wrap;
  word-break: normal;
`

export const Root = styled.div`
  position: fixed;
  z-index: 10000000;
  overflow: visible;
  left: 0;
  bottom: 0;
`

export const Panel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 32px;
  margin: 5px 5px 5px 5px;
  background-color: #ffffff;
  border: 1px solid #c6cad2;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  overflow: auto;
`

export const TriggerButton = styled.button`
  overflow: visible;
  line-height: 1;
  
  > svg {
    width: 30px;
    height: 30px;
    border-radius: 16px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .15);
    overflow: visible;
    vertical-align: middle;
  }
`

export const Button = styled.button<{activated?:boolean}>`
  display: inline-block;
  padding: 4px 6px;
  background: ${props => props.activated ? 'rgb(0, 122, 255)': '#f8f8fb'};
  border: #eaeaea 1px solid;
  border-radius: 4px;
  font-size: 1.3rem;
  color: ${props => props.activated ? '#fff' : 'rgb(0, 122, 255)'};
  letter-spacing: -.5px;
  text-align: center;
  & + & {
    margin-left: 5px;
  }

  &:active {
    background: #eaeaea;
  }
`

export const Pre = typeXsmall(typeBreakAll(typePreserveWrap(styled.pre`
  margin: 0;
`)))

export const Input = typeSmall(styled.input`
  appearance: none;
  border: 1px inset #777;
  border-radius: 2px;
`)

export const TextArea = typeSmall(styled.textarea`
  appearance: none;
  border: 1px solid #777;
  border-radius: 2px;
`)

export const Text = typeSmall(typeBreakAll(styled.span``))

export const Row = styled.li`
  border-bottom: 1px solid #f1f1f1;
  padding: 10px;
  display: flex;
  flex-direction: column;
`

export const Description = styled.p`
  font-size: 1.3rem;
  margin-top: 5px;
  color: #6e7685;
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ItemLabel = styled.span`
  background: #e8effb;
  line-height: 2;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #0064ff;
  padding: 0 6.5px;
  display: inline-block;
`
