import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {EducationQuizBaseData} from "@/network/apis/types";

export interface Props {
  data: EducationQuizBaseData;
  no: number;
}

interface Question {
  title: string;
  value: string;
}

function EduQuizMultiChoiceItem(props: Props) {
  const { data, no } = props
  const [wrongNo, setWrongNo] = useState('')
  const title = useMemo(() => {
    return `${no}. ${data.title}`
  }, [no, data.title])

  const items = useMemo(() => {
    const arr: Question[] = []
    for(let i = 1; i <= 5; i++){
      const question = data[('item'+i as keyof EducationQuizBaseData)]
      if(question && typeof question === 'string') arr.push({title: question, value: String(i)})
      else break
    }
    return arr
  }, [data])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    data.applyAnswer = e.target.value
    if(!wrongNo && data.answer !== data.applyAnswer){
      setWrongNo(data.applyAnswer)
      window.alert("정답이 아닙니다.")
    }
  }, [wrongNo, data])

  useEffect(() => {
    if(data.wrong) setWrongNo(data.wrong)
  }, [data.wrong])

  return (
      <div className={'quiz-item'}>
        <div className={'quiz-title'}>{title}</div>
        <div className={'quiz-answer-box'}>
          {
              items && items.map((item) => {
                const name = `edu-quiz-modal-item-${no}`
                const key = `${name}-${item.value}`
                return (
                    <div key={key} className={'quiz-answer-item'}>
                      <input id={key} type={'radio'} className={`inp type-radio`}
                             name={name}
                             onChange={handleChange}
                             value={item.value} />
                      <label htmlFor={key} className={`lab type-radio ${ (wrongNo === item.value) ? 'red' : ''}`}>{`${item.value}. ${item.title}`}</label>
                    </div>
                )
              })
          }
        </div>
      </div>
  )
}

export { EduQuizMultiChoiceItem }
