import {RefObject, useCallback, useRef} from "react";

export function usePrintRef(){
    const printRef = useRef<HTMLDivElement>(null)
    return {printRef}
}

interface Props{
    printRef: RefObject<HTMLDivElement>
}
export function usePrint(props: Props){
    const {printRef} = props

    return useCallback(({width = 790, height = 980}: {width?: number; height?: number;}) => {
        const mywindow = window.open('', 'PRINT', `height=${height},width=${width}`)
        if(mywindow && printRef.current){
            mywindow.document.write('<html><head>')
            const scriptList = document.getElementsByTagName('script')
            const styleList = document.getElementsByTagName('style')
            const linkList = document.getElementsByTagName('link')
            for(let i = 0; i < scriptList.length; i++){
                const script = scriptList[i]
                mywindow.document.write(script.outerHTML)
            }
            for(let i = 0; i < styleList.length; i++){
                const style = styleList[i]
                mywindow.document.write(style.outerHTML)
            }
            for(let i = 0; i < linkList.length; i++){
                const link = linkList[i]
                mywindow.document.write(link.outerHTML)
            }
            mywindow.document.write('</head>')
            mywindow.document.write('<body class="mode-print">')
            mywindow.document.write(printRef.current.outerHTML)
            mywindow.document.write('</body></html>')

            mywindow.document.close() // necessary for IE >= 10
            mywindow.focus() // necessary for IE >= 10

            setTimeout(function (){
                mywindow.print()
            }, 2000)
        }
    }, [printRef])
}
