import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {UserLevelCd} from "@/network/apis/types";

/**
 * 회원정보 리스트로 교육 신청하기
 * @param params
 * @param config
 */
export function postUserActionLogin(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        userId,
        userPw,
        userLevelCd,
    } = params
    const { params: configParams, ...restConfig } = config
    return axiosClient.post<ResponseData>('/api/user/action/login.do',
        {
            userId,
            userPw,
            userLevelCd,
            ...configParams,
        },
        {
            ...restConfig,
        },
    )
}

/**
 * userId: 아이디
 * userPw: 비밀번호
 * userLevelCd: 회원 타입 (비상, 초동, Ki)
 */
interface Params {
    userId: string;
    userPw: string;
    userLevelCd: UserLevelCd;
}


/**
 * result:              1 이상 성공, -1: 정보 불일치, -3: 5회 이상 로그인 실패
 * count:
 */
interface ResponseData {
    result: number;
    userLevelCd: UserLevelCd;
}

