import { createBrowserHistory } from 'history'
import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import App from './App'
import { BrowserRouter } from './providers/browser-router'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
import './styles/_index.scss'
import {getCommonCode, getUserMemberMyInfo} from "@/network";
import {GrpCd} from "@/network/apis/types";
import {setCommonCode} from "@/store/code";
import {setMyInfo} from "@/store/my-info";

const isPrd = process.env.REACT_APP_ENV === 'prd'
const DevTools = lazy(() => import('./dev-tools/RemisDevTools'))
const history = createBrowserHistory()

async function start() {

    const rootNode = document.getElementById('root');
    if (!rootNode) throw new Error('Failed to find the root element');

    try{
        const {data} = await getCommonCode({})
        for (const grpCd in data.list) {
            const castGrpCd = grpCd as GrpCd
            store.dispatch(setCommonCode({key: castGrpCd, list: data.list[castGrpCd]}))
        }
        const {data: myInfo} = await getUserMemberMyInfo({})
        store.dispatch(setMyInfo({data: myInfo}))

        ReactDOM.createRoot(rootNode).render(
            // <React.StrictMode>
                <Provider store={store}>
                    <BrowserRouter history={history}>
                        <App/>
                        {!isPrd && <Suspense fallback=""><DevTools/></Suspense>}
                    </BrowserRouter>
                </Provider>
            // </React.StrictMode>,
        )
    }catch (e){
        if(window.confirm('서버와의 통신이 원활하지 않습니다. 다시 시도하시겠습니까?')){
            start()
        }
    }
}

start()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
