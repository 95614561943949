import React, {useCallback, useMemo, useState} from 'react'
import {EducationLectureData} from "@/network/apis/types";
import {PdfButton, PlayButton, QuizButton} from "@/components/button";
import {useDownloadNote, useHaveNote} from "@/utils/hooks/use-note";
import moment from "moment";

interface Props {
    data: EducationLectureData;
    index: number;
    onPlayBtnClick: (data: EducationLectureData) => void;
    onQuizBtnClick: (data: EducationLectureData) => void;
}

export default function EduCourseGroupItem(props: Props) {
    const {data, index, onPlayBtnClick, onQuizBtnClick} = props
    const [extend, setExtend] = useState(false)
    const {haveNote} = useHaveNote({weekNo: data.weekNo})
    const downloadNote = useDownloadNote()

    const no = useMemo(() => index+1, [index])
    const title = useMemo(() => {
        return `${no}. ${data.csName}`
    }, [no, data.csName])

    const progress = useMemo(() => {
        return `${data.viewYmd ? `완료일: ${moment(data.viewYmd).format('YYYY.MM.DD')}, ` : ''}진행률: ${data.finished}/${data.csWeekCnt}`
    }, [data.viewYmd, data.finished, data.csWeekCnt])

    const showQuizBtn = useMemo(() => {
        return data.viewYmd && data.quizCnt > 0 && data.quizCnt > data.answerCnt
    }, [data.viewYmd, data.quizCnt, data.answerCnt])

    const handleExtendClick = useCallback(() => {
        setExtend(!extend)
    }, [extend])

    const handleDownloadPdfClick = useCallback(() => {
        downloadNote({weekNo: data.weekNo})
    }, [downloadNote, data.weekNo])

    return (
        data.isGroup === 'Y' ? (
            <button type={'button'} className={`step-content type-btn ${extend ? 'type-extend' : ''}`} onClick={handleExtendClick}>
                <div className={'step-inner'}>
                    <div className={'step-text'}>
                        <div className={'step-title'}>{title}</div>
                        <div className={'step-status'}>{progress}</div>
                    </div>
                </div>
            </button>
        ) : (
            <div className={'step-content'}>
                <div className={'step-inner'}>
                    <div className={'step-text'}>
                        <div className={'step-title'}>{title}</div>
                        <div className={'step-status'}>{progress}</div>
                    </div>
                    <div className={'step-btn-box'}>
                        {haveNote && (<PdfButton className={`step-sub-btn type-on`} onClick={handleDownloadPdfClick} />)}
                        {showQuizBtn && (<QuizButton className={`step-sub-btn type-on`} onClick={() => onQuizBtnClick(data)} />)}
                        <PlayButton className={`step-sub-btn ${data.viewYmd ? '' : 'type-on'}`} onClick={() => onPlayBtnClick(data)} />
                    </div>
                </div>
            </div>
        )

    )
}
