import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserEducationErrorLog} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {ParamEducationErrorLog} from "@/network/apis/types";

interface ResponseData {
    result: number;
}

export function useRegistUserEducationErrorLog() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: ParamEducationErrorLog) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            postUserEducationErrorLog(params, { cancelToken: canceler.token })
                .then( ({data}) => {
                    resolve(data)
                })
                .catch(() => {
                    reject()
                })
        })

    }, [prevCanceler, showGlobalLoading])
}