import React, {Dispatch, SetStateAction, useMemo} from 'react'
import {CommonOption} from "@/components";
import {GrpCd} from "@/network/apis/types";
import {useCommonCode} from "@/utils";

interface Props {
    defaultValue: string;
    className?: string;
    setValue: Dispatch<SetStateAction<string>>;
    disabled?: boolean;
    grpCd: GrpCd;
    filter?: string[];
    showAll?: boolean;
    showAllTitle?: string;
}

function CommonCodeOption(props: Props) {
    const {defaultValue, className, setValue, disabled, grpCd, filter, showAll, showAllTitle} = props
    const commonCdList = useCommonCode({grpCd})
    const renderList = useMemo(() => {
        if(filter && commonCdList) return commonCdList.filter((item) => filter.indexOf(item.commCd) !== -1)
        else return commonCdList
    }, [filter, commonCdList])

    return (
        <>
            <CommonOption<string>
                className={className}
                defaultValue={defaultValue}
                setValue={setValue}
                showAll={showAll}
                showAllTitle={showAllTitle}
                disabled={disabled}
            >
                {
                    renderList && renderList.map((item, index) => {
                        const key = `option-common-code-item-${item.commCd}-${index}`
                        return (
                            <option key={key} value={item.commCd}>{item.commNm}</option>
                        )
                    })
                }
            </CommonOption>
        </>
    )
}

export { CommonCodeOption }