import Axios from 'axios'
import {useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {CustomerQnaData, FileData} from "@/network/apis/types";
import {getUserCustomerQnaDetail} from "@/network";

interface SearchParams {
    qnaNo: number;
}

const emptyData: CustomerQnaData = {
    title: '',
    rownum: 0,
    deptNm: '',
    deptCd: '',
    regYmd: '',
    ansUserNm: '',
    answer: '',
    question: '',
    ansYmd: '',
    ansYn: '',
    ansYnNm: '',
    qnaNo: 0,
    reqUserNm: '',
    reqUserNo: 0,
    userNm: '',
}
export function useFetchUserCustomerQnaDetail(searchParams: SearchParams) {
    const {qnaNo} = searchParams
    const [detail, setDetail] = useState<CustomerQnaData>(emptyData)
    const [files, setFiles] = useState<FileData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserCustomerQnaDetail({qnaNo}, { cancelToken: canceler.token })
                .then(({data}) => {
                    if(data.result){
                        setDetail(data.result.detail)
                        setFiles(data.result.files)
                    }else{
                        setDetail(emptyData)
                        setFiles([])
                    }
                })
                .catch(() => {
                    setDetail(emptyData)
                    setFiles([])
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, qnaNo])

    return {detail, files}
}
