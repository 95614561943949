import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useEduQuizModal, useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import icoModalClose from "@/assets/images/ico_modal_close.svg";
import icoModalControl from "@/assets/images/ico_arrow.svg";
import {EduCourseContainer} from "@/components/edu-course";
import {EducationLectureData} from "@/network/apis/types";
import {
  getFileUrl,
  useGetUserEducationLectureLogSelect,
  useGetUserEducationLectureQuizList, useRegistUserEducationErrorLog,
  useRegistUserEducationLectureLog,
  useRegistUserEducationLectureLogFrameUpdate,
  useRegistUserEducationLectureRequest
} from "@/utils";
import {ControlBar, ControlBarControlProps, Player, PlayerReference, PlayerState} from 'video-react';
import 'video-react/dist/video-react.css';
import {videoList, videoWeekData} from "@/data/videoData";
import {useGetPublicCheckSession} from "@/utils/hooks/apis/public";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error; // import css

export interface EduVideoModalProps extends ModalCommonProps {
  eduNo: number;
  weekNo: number;
  applyNo: number;
  weekUrl: string;
  viewYmd: string;
  list: EducationLectureData[];
  onOk: () => void;
  onCancel?: () => void;
}

enum EndedState {
  Progress,
  EndedVideo,
  EndedWeek,
}

function EduVideo(props: EduVideoModalProps) {
  const { eduNo, weekNo, applyNo, weekUrl, viewYmd, list, onOk, onCancel } = props
  const { closeModal } = useModalManager()
  const player = useRef<PlayerReference>(null)
  const [playerState, setPlayerState] = useState<PlayerState | null>(null)
  const [videoEduNo, setVideoEduNo] = useState<number>(eduNo)
  const [videoApplyNo, setVideoApplyNo] = useState<number>(applyNo)
  const [videoWeekNo, setVideoWeekNo] = useState<number>(weekNo)
  const [videoWeekUrl, setVideoWeekUrl] = useState<string>(weekUrl)
  const [videoViewYmd, setVideoViewYmd] = useState<string>(viewYmd)
  const [activeTrack, setActiveTrack] = useState(true)

  const [ready, setReady] = useState(false)
  const [start, setStart] = useState(false)
  const [baseSrc, setBaseSrc] = useState('')
  const [logNo, setLogNo] = useState(0)
  const [currNum, setCurrNum] = useState(0)
  const [lenNum, setLenNum] = useState(0)
  const currNumStr = useMemo(() => currNum < 10 ? '0'+currNum : currNum, [currNum])
  const lenNumStr = useMemo(() => lenNum < 10 ? '0'+lenNum : lenNum, [lenNum])
  const [title, setTitle] = useState('')
  const fileNm = useMemo(() => {
    if(baseSrc){
      const splitted = baseSrc.split("/");
      return splitted[splitted.length-1]
    }else{
      return ''
    }
  }, [baseSrc])
  const [frame, setFrame] = useState(0)
  const [initFrame, setInitFrame] = useState(false)
  const [errorTime, setErrorTime] = useState(0)
  const videoData = useMemo(() => {
    return baseSrc ? videoList[baseSrc] : null
  }, [baseSrc])
  const videoWeek = useMemo(() => {
    return videoData ? videoWeekData[videoData.path] : null
  }, [videoData])
  const videoSrc = useMemo(() => {
    return `${getFileUrl(baseSrc.replace(".html", ".mp4"))}${errorTime ? `?t=${errorTime}` : ''}`
  }, [baseSrc, errorTime])
  const videoVtt = useMemo(() => {
    return videoData?.subtitles ? baseSrc.replace(".html", ".vtt") : ''
  }, [baseSrc, videoData])
  const ended = useMemo(() => {
    if(playerState && playerState.ended) return true
    return false
  }, [playerState])
  const [endedState, setEndedState] = useState<EndedState>(EndedState.Progress)

  const getUserEducationLectureLogSelect = useGetUserEducationLectureLogSelect()
  const registUserEducationLectureLog = useRegistUserEducationLectureLog()
  const registUserEducationLectureLogFrameUpdate = useRegistUserEducationLectureLogFrameUpdate()
  const registUserEducationLectureRequest = useRegistUserEducationLectureRequest()
  const registUserEducationErrorLog = useRegistUserEducationErrorLog()
  const getUserEducationLectureQuizList = useGetUserEducationLectureQuizList()
  const getPublicCheckSession = useGetPublicCheckSession()
  const eduQuizModal = useEduQuizModal()

  /**
   * 동영상 수강 중 session 유지를 위해 1분마다 호출
   */
  const checkSession = useCallback(async () => await getPublicCheckSession(), [getPublicCheckSession])
  useEffect(() => {
    const timer = setInterval(() => checkSession(), 60000)
    return () => clearInterval(timer)
  }, [checkSession])

  /**
   * video player 동영상 종료 event 체크를 위한 상태값 갱신
   */
  useEffect(() => {
    if(player && player.current){
      player.current.subscribeToStateChange((current) => {
        setPlayerState(current)
      })
      if(!initFrame && frame){
        player.current.seek(frame)
        setInitFrame(true)
      }
    }
  }, [player, initFrame, frame])

  /**
   * 강의 수강 화면 최초 진입
   * getUserEducationLectureLogSelect로 해당 차시의 진행중인 강의가 있는지 조회
   * 하나의 차시에는 동영상이 1개일 수도 있지만 여러개일 수 있우므로 몇 번째 동영상부터 시작할 것인지 조회가 필요
   */
  const callGetUserEducationLectureLogSelect = useCallback(async () => {
    const {result} = await getUserEducationLectureLogSelect({eduNo: videoEduNo, weekNo: videoWeekNo})
    if(result?.fileNm){
      let splitted = videoWeekUrl.split("/")
      setBaseSrc(videoWeekUrl.replace(splitted[splitted.length-1], result.fileNm))
    }else{
      setBaseSrc(videoWeekUrl)
    }
    setFrame(videoViewYmd ? 0 : (result?.frame || 0))
  }, [getUserEducationLectureLogSelect, videoEduNo, videoWeekNo, videoWeekUrl, videoViewYmd])

  useEffect(() => {
    if(!ready){
      callGetUserEducationLectureLogSelect()
      setReady(true)
    }
  }, [ready, callGetUserEducationLectureLogSelect])

  /**
   * 강의 수강 시작
   * 특정 동영상이 선택 되었으므로 강의 수강 시작 (로그 삽입)
   */
  const startEducation = useCallback(async () => {
    if(videoData && videoWeek){
      const {logNo} = await registUserEducationLectureLog({eduNo: videoEduNo, weekNo: videoWeekNo, fileNm, frame: 0})
      setFrame(0)
      setCurrNum(videoData.cnt)
      setLenNum(videoWeek.len)
      setTitle(videoWeek.title)
      setLogNo(logNo)
    }
  }, [videoEduNo, videoWeekNo, fileNm, videoData, videoWeek, registUserEducationLectureLog])

  useEffect(() => {
    if(ready && !start && videoData){
      startEducation()
      setStart(true)
    }
  }, [ready, start, videoData, startEducation])

  /**
   * 30초마다 frame 저장
   */
  const callRegistUserEducationLectureLogFrameUpdate = useCallback(async (saveFrame: number) => {
    setFrame(saveFrame)
    await registUserEducationLectureLogFrameUpdate({frame: saveFrame, logNo})
  }, [registUserEducationLectureLogFrameUpdate, logNo])

  useEffect(() => {
    const timer = setTimeout(() => {
      if(playerState && playerState.currentTime >= frame+30){
        callRegistUserEducationLectureLogFrameUpdate(playerState.currentTime)
      }
    }, 1)
    return () => clearTimeout(timer)
  }, [playerState, frame, callRegistUserEducationLectureLogFrameUpdate])

  useEffect(() => {
    const timer = setTimeout(() => {
      if(playerState && playerState.duration){
        // @ts-ignore
        if(playerState.error){
          // @ts-ignore
          console.log(playerState.error)
          setErrorTime(playerState.currentTime+Math.random())
          registUserEducationErrorLog({eduNo, weekNo, fileNm, frame})
              .then(() => {
                console.log('registUserEducationErrorLog success')
              })
              .catch(() => {
                console.log('registUserEducationErrorLog error')
              })
        }
        /*
        // 10초에서 리셋하는 test code
        if(playerState.currentTime > 10 && playerState.currentTime < 11){
          setErrorTime(playerState.currentTime+Math.random())
        }*/
      }
    }, 1)
    return () => clearTimeout(timer)
    // @ts-ignore
  }, [playerState?.duration, playerState?.currentTime, playerState?.error, registUserEducationErrorLog, eduNo, weekNo, fileNm, frame])

  useEffect(() => {
    if(player && player.current && errorTime){
      player.current.seek(errorTime)
    }
  }, [errorTime, player])

  /**
   * handler 정의
   */
  const handlePrevClick = useCallback(() => {
    if(videoData && videoWeek){
      if(currNum > 1){
        let targetNum = currNum-1
        const targetNumStr = videoWeek.fillZero && targetNum < 10 ? '0'+targetNum : targetNum
        setBaseSrc(`${videoData.path}${targetNumStr}.html`)
        setFrame(0)
        setTimeout(() => setStart(false), 100)
        setEndedState(EndedState.Progress)
      }
    }
  }, [currNum, videoData, videoWeek])

  const handleNextClick = useCallback(() => {
    if(videoData && videoWeek){
      if(currNum && lenNum && currNum < lenNum){
        let targetNum = currNum+1
        const targetNumStr = videoWeek.fillZero && targetNum < 10 ? '0'+targetNum : targetNum
        setBaseSrc(`${videoData.path}${targetNumStr}.html`)
        setFrame(0)
        setTimeout(() => setStart(false), 100)
        setEndedState(EndedState.Progress)
      }
    }
  }, [currNum, lenNum, videoData, videoWeek])

  const handleTrackClick = useCallback(() => {
    setActiveTrack(!activeTrack)
  }, [activeTrack])

  const handleVideoChange = useCallback(({eduNo, weekNo, applyNo, weekUrl, viewYmd}: {
    eduNo: number;
    weekNo: number;
    applyNo: number;
    weekUrl: string;
    viewYmd: string;
  }) => {
    setVideoEduNo(eduNo)
    setVideoWeekNo(weekNo)
    setVideoApplyNo(applyNo)
    setVideoWeekUrl(weekUrl)
    setVideoViewYmd(viewYmd)
    setBaseSrc('')
    setFrame(0)
    setEndedState(EndedState.Progress)
    setTimeout(() => [setReady(false), setStart(false)], 100)
  }, [])

  /**
   * 영상 종료
   */
  const callRegistUserEducationLectureRequest = useCallback(async () => {
    const {result} = await registUserEducationLectureRequest({eduNo: videoEduNo, weekNo: videoWeekNo, applyNo: videoApplyNo})
    if(result >= 1){
      const {list} = await getUserEducationLectureQuizList({eduNo: videoEduNo, weekNo: videoWeekNo})
      if(list.length){
        eduQuizModal({
          list,
          eduNo: videoEduNo,
          weekNo: videoWeekNo,
          onOk: () => {
            closeModal()
            onOk()
          }
        })
      }else{
        closeModal()
        onOk()
      }
    }
  }, [registUserEducationLectureRequest, videoEduNo, videoWeekNo, videoApplyNo, eduQuizModal, getUserEducationLectureQuizList, onOk, closeModal])

  useEffect(() => {
    const timer = setTimeout(() => {
      if(ended && playerState?.currentTime){
        if(currNum >= lenNum){
          if(endedState !== EndedState.EndedWeek){
            setEndedState(EndedState.EndedWeek)
            callRegistUserEducationLectureRequest()
          }
        }else{
          if(endedState !== EndedState.EndedVideo){
            setEndedState(EndedState.EndedVideo)
            if(window.confirm(`${title} ${currNum}/${lenNum} \r\n다음 강의로 이동하시겠습니까?`)){
              handleNextClick()
            }
          }
        }
      }else{
        setEndedState(EndedState.Progress)
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [ended, endedState, playerState, title, currNum, lenNum, callRegistUserEducationLectureRequest, handleNextClick])

  return (
      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-video'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>{title}</span>
            <div className={'modal-info'}>
              <div className={'modal-control-box'}>
                {
                    lenNum > 1 && currNum > 1 && (
                        <button type={'button'} className={'modal-control-btn'} onClick={handlePrevClick}>
                          <div className={'modal-control-btn-inner type-prev'}>
                            <img alt="remis" src={icoModalControl} className={'modal-control-ico'}/>
                          </div>
                        </button>
                    )
                }
                <div className={'modal-control-step'}>{currNumStr} / {lenNumStr}</div>
                {
                    (ended || videoViewYmd) && lenNum > 1 && currNum < lenNum && (
                        <button type={'button'} className={'modal-control-btn'} onClick={handleNextClick}>
                          <div className={'modal-control-btn-inner type-next'}>
                            <img alt="remis" src={icoModalControl} className={'modal-control-ico'}/>
                          </div>
                        </button>
                    )
                }
              </div>
              <button type="button" className={'modal-close-btn'} onClick={() => {
                closeModal()
                onCancel && onCancel()
              }}>
                <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
              </button>
            </div>
          </div>
          <div className={`modal-video-box ${videoViewYmd ? 'can-control' : ''}`}>
            <Player
                ref={player}
                playsInline
                autoPlay={true}
                src={videoSrc}
            >
              {
                [0].map(() => {
                  return (
                      <track
                          key={videoVtt}
                          default
                          kind={'captions'}
                          label={'한국어'}
                          src={activeTrack && videoVtt ? videoVtt : ''}
                          srcLang={'ko'}
                      />
                  )
                })
              }
              <ControlBar autoHide={true}>
                <TrackButton order={7} active={activeTrack} hidden={videoVtt ? false : true} onClick={handleTrackClick}/>
              </ControlBar>
            </Player>
          </div>
          <EduCourseContainer type={'modal'} list={list} className={'type-modal'} onChange={handleVideoChange}/>

        </div>
      </div>
  )
}

interface TrackButtonProps extends ControlBarControlProps{
  active: boolean;
  hidden: boolean;
  onClick: () => void;
}
function TrackButton(props: TrackButtonProps){
  const {active, hidden, onClick} = props
  return <button className={`video-react-time-control-track ${active ? 'on' : ''} ${hidden ? 'hidden' : ''}`} type={'button'} onClick={onClick}>Cc</button>
}

export { EduVideo }
