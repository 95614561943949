import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {BbsData, ListResQueryData} from "@/network/apis/types";

/**
 * 공지사항 리스트
 * @param params
 * @param config
 */
export function getUserNoticeList(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        pageIndex,
        searchCondition,
        searchKeyword,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/notice/list.do', {
        ...restConfig,
        params: {
            pageIndex,
            searchCondition,
            searchKeyword,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * pageIndex:           페이지 번호
 * searchCondition:     검색 필드 (1: 제목, 2: 내용)
 * searchKeyword:       검색어
 */
interface Params {
    pageIndex: number;
    searchCondition?: '1' | '2';
    searchKeyword?: string;
}


interface ResponseData {
    list: BbsData[];
    notiList: BbsData[];
    paginationInfo: ListResQueryData;
}

