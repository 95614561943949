import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {CertiData, UserLevelCd} from "@/network/apis/types";
import {getUserEducationPassCompleteLicense} from "@/network";

interface Params {
    applyNo: number;
    userLevelCd: UserLevelCd;
}

interface ResponseData {
    result: CertiData;
}

export function useGetUserEducationPassCompleteLicense() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: Params) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                getUserEducationPassCompleteLicense(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        resolve(data)
                    })
                    .catch(() => {
                        reject()
                    })
            )
        })
    }, [prevCanceler, showGlobalLoading])
}
