import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '@/store'

type AppState = typeof initialState

interface Actions extends SliceCaseReducers<AppState> {
  setIsMobile(state: AppState, action: PayloadAction<boolean>): void;
}

const initialState = {
  isMobile: false,
}

export const appSlice = createSlice<AppState, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setIsMobile(state, action) {
      const { payload } = action
      state.isMobile = payload
    },
  },
})

export const {
  setIsMobile,
} = appSlice.actions

export const selectAppIsMobile = (state: RootState) => state.app.isMobile