import React, {ChangeEvent, useCallback, useMemo} from 'react'
import {EducationSurveyData} from "@/network/apis/types";

export interface Props {
  data: EducationSurveyData;
  no: number;
}

function EduSurveyMultiChoiceItem(props: Props) {
  const { data, no } = props
  const title = useMemo(() => {
    return `${no}. ${data.gubun ? `[${data.gubun}] ` : ''}${data.title}`
  }, [no, data.title, data.gubun])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    data.applyAnswer = e.target.value
  }, [data])

  return (
      <div className={'survey-item'}>
        <div className={'survey-question'}>{title}</div>
        <div className={'survey-answer'}>
          <div className={'inp-box type-survey'}>
            <div className={'inp-item'}>
              <input id={`servey-modal-item-${data.questionNo}-${no}-1`} type={'radio'} className={'inp type-radio'}
                     name={`servey-modal-item-${data.questionNo}-${no}`}
                     defaultChecked={data.applyAnswer === '1'}
                     onChange={handleChange}
                     value={"1"} />
              <label htmlFor={`servey-modal-item-${data.questionNo}-${no}-1`} className={'lab type-radio'}>①</label>
            </div>
            <div className={'inp-item'}>
              <input id={`servey-modal-item-${data.questionNo}-${no}-2`} type={'radio'} className={'inp type-radio'}
                     name={`servey-modal-item-${data.questionNo}-${no}`}
                     defaultChecked={data.applyAnswer === '2'}
                     onChange={handleChange}
                     value={"2"} />
              <label htmlFor={`servey-modal-item-${data.questionNo}-${no}-2`} className={'lab type-radio'}>②</label>
            </div>
            <div className={'inp-item'}>
              <input id={`servey-modal-item-${data.questionNo}-${no}-3`} type={'radio'} className={'inp type-radio'}
                     name={`servey-modal-item-${data.questionNo}-${no}`}
                     defaultChecked={data.applyAnswer === '3'}
                     onChange={handleChange}
                     value={"3"} />
              <label htmlFor={`servey-modal-item-${data.questionNo}-${no}-3`} className={'lab type-radio'}>③</label>
            </div>
            <div className={'inp-item'}>
              <input id={`servey-modal-item-${data.questionNo}-${no}-4`} type={'radio'} className={'inp type-radio'}
                     name={`servey-modal-item-${data.questionNo}-${no}`}
                     defaultChecked={data.applyAnswer === '4'}
                     onChange={handleChange}
                     value={"4"} />
              <label htmlFor={`servey-modal-item-${data.questionNo}-${no}-4`} className={'lab type-radio'}>④</label>
            </div>
            <div className={'inp-item'}>
              <input id={`servey-modal-item-${data.questionNo}-${no}-5`} type={'radio'} className={'inp type-radio'}
                     name={`servey-modal-item-${data.questionNo}-${no}`}
                     defaultChecked={data.applyAnswer === '5'}
                     onChange={handleChange}
                     value={"5"} />
              <label htmlFor={`servey-modal-item-${data.questionNo}-${no}-5`} className={'lab type-radio'}>⑤</label>
            </div>
          </div>
        </div>
      </div>
  )
}

export { EduSurveyMultiChoiceItem }
