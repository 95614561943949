import {useCallback, useEffect, useState} from 'react';
import {downloadFile} from "@/utils";

interface Props {
  weekNo: string;
}

const haveNoteWeekNoList = ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17']

export function useHaveNote(props: Props) {
  const {weekNo} = props
  const [haveNote, setHaveNote] = useState(false)
  useEffect(() => {
    setHaveNote(haveNoteWeekNoList.findIndex((item) => item === weekNo) !== -1)
  }, [weekNo])
  return {haveNote}
}

export function useDownloadNote() {
  return useCallback((props: Props) => {
    const {weekNo} = props
    downloadFile(`/template/book/book_${weekNo}.pdf`)
  }, [])
}