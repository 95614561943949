import { useCallback } from 'react'
import {FindIdPw, FindIdPwModalProps} from '../FindIdPw'
import { useModalManager } from './use-modal-manager'

export function useFindIdPwModal() {
  const { showModal } = useModalManager()
  return useCallback((options: FindIdPwModalProps) => {
    showModal({
      component: FindIdPw,
      props: options as FindIdPwModalProps,
    })
  }, [showModal])
}
