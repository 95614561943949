import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 전체 차시 viewed
 * @param params
 * @param config
 */
export function postUserEducationLectureRequest(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo, weekNo, applyNo,
    } = params

    const frm = new FormData()
    frm.append('gubun', '')
    frm.append('eduNo', String(eduNo))
    frm.append('weekNo', String(weekNo))
    frm.append('applyNo', String(applyNo))

    return axiosClient.post<ResponseData>('/api/user/education/lecture/request.do',
        frm,
        config,
    )
}

interface Params {
    eduNo: number;
    weekNo: number;
    applyNo: number;
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

