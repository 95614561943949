import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 회원정보 리스트로 교육 신청하기
 * @param params
 * @param config
 */
export function postUserMemberAgreeMember(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const { params: configParams, ...restConfig } = config
    return axiosClient.post<ResponseData>('/api/user/member/agreeMember.do',
        {
            ...configParams,
        },
        {
            ...restConfig,
        },
    )
}

/**
 * 파라미터 없음
 */
interface Params {
}


/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

