import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {ParamEducationExamAnswer} from "@/network/apis/types";

/**
 * 문제풀이 정답 입력 API
 * @param params
 * @param config
 */
export function postUserEducationLectureExamAnswer(params: ParamEducationExamAnswer, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo, list
    } = params

    const frm = new FormData()
    frm.append('eduNo', String(eduNo))
    frm.append('examflag', '0')
    list.map((item) => {
        frm.append('arrExamNo[]', String(item.examNo))
        return false
    })
    list.map((item) => {
        frm.append('arrExamAnswer[]', item.answer)
        return false
    })
    list.map((item) => {
        frm.append('arrApplyAnswer[]', item.applyAnswer)
        return false
    })

    return axiosClient.post<ResponseData>('/api/user/education/exam/answer.do',
        frm,
        config,
    )
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

