import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {getPublicCheckSession} from "@/network";

interface ResponseData {
    result: number;
}

export function useGetPublicCheckSession() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)

    return useCallback(() => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            getPublicCheckSession({}, { cancelToken: canceler.token })
                .then( ({data}) => {
                    const {result} = data
                    if(result >= 1){
                        resolve(data)
                    }else if(result === -99){
                        alert("중복로그인으로 자동 로그아웃되었습니다.");
                        reject()
                        window.location.href = '/uat/uia/actionLogout.do'
                    }else{
                        alert("로그아웃 되었습니다. 다시 로그인 후 사용해 주세요.");
                        reject()
                        window.location.href = '/uat/uia/actionLogout.do'
                    }

                })
                .catch(() => {
                    reject()
                    window.location.href = '/uat/uia/actionLogout.do'
                })
        })
    }, [prevCanceler])
}
