import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 아이디 중복 체크
 * @param params
 * @param config
 */
export function getUserMemberCheckUserId(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        userId,
        authType = 1,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/uat/uia/checkUserId.do', {
        ...restConfig,
        params: {
            userId,
            authType,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * userId:      중복 체크할 아이디
 * authType:    0: 관리자, 1: 실무자
 */
interface Params {
    userId: string;
    authType?: number;
}

/**
 * result:  true or false
 */
interface ResponseData {
    result: boolean;
}

