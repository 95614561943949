import { useEffect, useState } from 'react'
import { useListenHistory } from '../../providers/browser-router'
import { useModalManager } from './hooks'
import { addModalListListener, getModalList, popModal } from './modal-manager'

export function ModalContainer() {
  const [modalList, setModalList] = useState(() => getModalList())
  const listenHistory = useListenHistory()
  const { closeModal } = useModalManager()

  useEffect(() => {
    return addModalListListener(setModalList)
  }, [])

  useEffect(() => {
    return listenHistory(({ action }) => {
      if (action === 'POP') {
        popModal()
      }
    })
  }, [listenHistory, modalList, closeModal])

  return (
    <>
      {modalList.map(({ component: Component, props }) =>
        // props에 내부 관리용 id를 넣음. modal-manager.tsx#pushModal 참고.
        <Component {...props} key={props.id}/>
      )}
    </>
  )
}
