import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {CustomerQnaData, FileData} from "@/network/apis/types";

/**
 * 고객센터 상세
 * @param params
 * @param config
 */
export function getUserCustomerQnaDetail(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        qnaNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/customer/qna/detail.do', {
        ...restConfig,
        params: {
            qnaNo,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 고객센터 번호
 */
interface Params {
    qnaNo: number;
}


interface ResponseData {
    result: {
        detail: CustomerQnaData;
        files: FileData[];
    }
}

