import {useCallback} from "react";

/**
 * result: 1: 성공, 0: 실패
 */
export interface OkNameResult {
    result: number;
    di: string;
    name: string;
    birthday: string;
    hp: string;
}

interface Props {
    onOk: (result: Omit<OkNameResult, 'result'>) => void;
}

export function useOkName(props: Props){
    const {onOk} = props
    const callbackOkNameResult = useCallback(({result, di, name, birthday, hp}: OkNameResult) => {
        result === 1 && onOk({di, name, birthday, hp})
    }, [onOk])

    return useCallback(() => {
        const okNameForm = document.forms.namedItem('okNameForm');
        if(okNameForm){
            // @ts-ignore
            window.callbackOkNameResult = callbackOkNameResult
            window.open('', 'auth_popup', 'width=430,height=640,scrollbars=yes');
            okNameForm.target = "auth_popup";
            okNameForm.submit();
        }
    }, [callbackOkNameResult])
}