import { Listener } from 'history'
import { useCallback, useContext } from 'react'
import { HistoryContext } from '../BrowserRouter'

export function useListenHistory() {
  const history = useContext(HistoryContext)
  return useCallback((listener: Listener) => {
    return history.listen(listener)
  }, [history])
}
