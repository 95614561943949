import { useEffect, useRef } from "react"

function useEffectOnce(hook: () => (() => void) | void) {
    const flag = useRef(false)

    useEffect(() => {
        if (flag.current) {
            return
        }
        flag.current = true
        hook()
    }, [hook])
}

export { useEffectOnce }
