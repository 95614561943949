import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 수료증 스킨 정보
 * @param params
 * @param config
 */
export function getUserEducationPassCertiForm(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/common/certificate/form.do', {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 */
interface Params {
    searchEduFlag: string;
}


interface ResponseData {
    certificate: {
        skinFlag: string;
    };
}

