import {useMemo} from "react";
import {useSelector} from "react-redux";
import {selectMyInfo} from "@/store/my-info";

export function useUserLoggedIn(){
    const myInfo = useSelector(selectMyInfo)
    const loggedIn = useMemo(() => {
        return myInfo.result !== -1
    }, [myInfo.result])
    return {loggedIn}
}