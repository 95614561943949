import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {EducationData} from "@/network/apis/types";

/**
 * 진행중인(신청가능한) 강의 리스트
 * @param params
 * @param config
 */
export function getUserEducationList(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/education/list.do', {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 없음
 */
interface Params {
}


interface ResponseData {
    list: EducationData[];
}

