import React, {useEffect, useMemo, useState} from 'react'
import {EducationSurveyData} from "@/network/apis/types";
import {CommonInput} from "@/components";

export interface Props {
  data: EducationSurveyData;
  no: number;
}

function EduSurveyShortAnsItem(props: Props) {
  const { data, no } = props
  const [value, setValue] = useState(data.applyAnswer || '')

  const title = useMemo(() => {
    return `${no}. ${data.gubun ? `[${data.gubun}] ` : ''}${data.title}`
  }, [no, data.title, data.gubun])

  useEffect(() => {
    data.applyAnswer = value
    // eslint-disable-next-line
  }, [value])

  return (
      <div className={'survey-item'}>
        <div className={'survey-question'}>{title}</div>
        <div className={'survey-answer'}>
          <CommonInput type={'textarea'} className={'tf type-area type-survey'} value={value} setValue={setValue} />
        </div>
      </div>
  )
}

export { EduSurveyShortAnsItem }
