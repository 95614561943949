import React, {ChangeEvent, useCallback, useState} from 'react'
import {useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import {useRegistUserMemberAgreeMember} from "@/utils";

export interface TermsModalProps extends ModalCommonProps {
  onOk: () => void;
  onCancel?: () => void;
}

function Terms(props: TermsModalProps) {
  const { onOk } = props
  const { closeModal } = useModalManager()
  const [agree1, setAgree1] = useState('')
  const [agree2, setAgree2] = useState('')
  const registUserMemberAgreeMember = useRegistUserMemberAgreeMember()

  const handleAgree1Change = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAgree1(e.target.value)
  }, [])

  const handleAgree2Change = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAgree2(e.target.value)
  }, [])

  const handleSaveClick = useCallback(async () => {
    if(agree1 !== 'Y'){
      window.alert("개인정보 수집 및 이용 안내에 동의 해 주시기를 바랍니다.\r\n동의하지 않을 경우 회원가입이 제한됩니다.");
    }else if(agree2 !== 'Y'){
      window.alert("개인정보 제3자 제공에 관하여 동의 해 주시기를 바랍니다.\r\n동의하지 않을 경우 회원가입이 제한됩니다.");
    }else{
      try{
        const {result} = await registUserMemberAgreeMember()
        if(result >= 1){
          closeModal()
          onOk()
        }
      }catch(e){console.log(e)}
    }
  }, [agree1, agree2, registUserMemberAgreeMember, onOk, closeModal])

  return (
      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-regist'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>개인정보 수집·이용 및 제공에 관한 동의</span>
          </div>
          <div className={'modal-content'}>
            <div className={'tos-box'}>
              <div className={'tos-item'}>
                <div className={'mobile-check'}>
                  <div className={'mobile-check-key'}>한국원자력의학원은 개인정보보호법을 준수하여 귀하의 소중한 개인정보를 안전하게 관리하며, ｢원자력시설 등의 방호 및 방사능방재대책법｣ 제39조(국가방사선비상진료체제의 구축)에 근거하여 방사선비상진료 지원사업 참여 및 요원활동 등과 관련된 개인정보를 수집·이용·제공하는데 동의를 받고자 합니다</div>
                </div>
              </div>
              <div className={'tos-item'}>
                <div className={'tos-title'}>개인정보 수집·이용에 관한 동의</div>
                <div className={'tos-content'} dangerouslySetInnerHTML={{__html:`1. 개인정보의 수집·이용 목적<br/>
              ① 방사선비상진료를 위한 교육･훈련 대상자 관리･활용<br/>
              ② 국가방사선비상진료센터 사업 계획 및 결과 보고<br/>
              <br/>
              2. 수집하려는 개인정보의 항목<br/>
              ① 필수 : 소속기관 및 부서, 성명, 생년월일, 이메일, 직종, 휴대전화번호<br/>
              ② 선택 : 의사면허번호<br/>

              3. 개인정보의 보유 및 이용 기간<br/>
              방사선비상진료요원 해제 후 5년 보관<br/>
                <br/>
              4. 동의 거부권 및 동의 거부에 따른 불이익<br/>
              위와 같이 개인은 개인정보를 수집 및 이용하는 데에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 방사선비상진료요원 참여 및 활동이 제한될 수 있음을 확인합니다.<br/>
                <br/>
              5. 개인정보 수집･이용 및 제공의 동의 철회<br/>
              동의서 제출 등으로 이루어진 개인정보 수집‧이용 및 제공 동의는 철회가 가능하며 담당부서로 연락 시 개인정보 삭제 등 필요조치 예정<br/>
              `}}>
                </div>
                <div className={'inp-box type-tos'}>
                  <div className={'inp-item'}>
                    <input id="terms-modal-agree-1-y" name="terms-modal-agree-1" type={'radio'} className={'inp type-radio'} value={'Y'} checked={agree1 === 'Y'} onChange={handleAgree1Change} />
                    <label htmlFor="terms-modal-agree-1-y" className={'lab type-radio'}>동의합니다</label>
                  </div>
                  <div className={'inp-item'}>
                    <input id="terms-modal-agree-1-n" name="terms-modal-agree-1" type={'radio'} className={'inp type-radio'} value={'N'} checked={agree1 === 'N'} onChange={handleAgree1Change} />
                    <label htmlFor="terms-modal-agree-1-n" className={'lab type-radio'}>동의하지 않습니다</label>
                  </div>
                </div>
              </div>
              <div className={'tos-item'}>
                <div className={'tos-title'}>개인정보 제3자 제공에 관한 동의</div>
                <div className={'tos-content'} dangerouslySetInnerHTML={{__html:`1. 개인정보를 제공받는 자<br/>
                        원자력안전위원회(원안위), 기획재정부, 대한의사협회<br/>
                        <br/>
                        2. 개인정보를 제공받는 자의 개인정보 이용목적<br/>
                        ① 방사선비상진료요원 지정현황 관리(원자력안전위원회)<br/>
                        ② 공공기관 고객만족도 조사(기획재정부)<br/>
                        ③ 의사 연수교육 평점 결과보고(대한의사협회)<br/>
                        <br/>
                        3. 제공되는 개인정보항목<br/>
                        소속기관 및 부서, 성명, 생년월일, 이메일, 직종, 휴대전화번호, 의사면허번호<br/>
                        <br/>
                        4. 개인정보의 보유 및 이용기간<br/>
                        ① 원자력안전위원회 : 5년 보관<br/>
                        ② 기획재정부 : 3년 보관<br/>
                        ③ 대한의사협회 : 3년 보관<br/>
                        <br/>
                        5. 동의 거부 권리 및 동의 거부에 따른 제한사항<br/>
                        개인은 위와 같이 개인정보를 제공하는 데에 동의를 거부할 권리가 있으며, 동의를 거부할 경우 방사선비상진료 교육활동 일부가 제한될 수 있음을 확인합니다.<br/>
              `}}>
                </div>
                <div className={'inp-box type-tos'}>
                  <div className={'inp-item'}>
                    <input id="terms-modal-agree-2-y" name="terms-modal-agree-2" type={'radio'} className={'inp type-radio'} value={'Y'} checked={agree2 === 'Y'} onChange={handleAgree2Change} />
                    <label htmlFor="terms-modal-agree-2-y" className={'lab type-radio'}>동의합니다</label>
                  </div>
                  <div className={'inp-item'}>
                    <input id="terms-modal-agree-2-n" name="terms-modal-agree-2" type={'radio'} className={'inp type-radio'} value={'N'} checked={agree2 === 'N'} onChange={handleAgree2Change} />
                    <label htmlFor="terms-modal-agree-2-n" className={'lab type-radio'}>동의하지 않습니다</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'btn-box type-large type-center type-modal'}>
            <button type="button" className={'btn type-large type-apply'} onClick={handleSaveClick}>확인</button>
          </div>
        </div>
      </div>
  )
}

export { Terms }
