import React, { PropsWithChildren, ReactElement } from 'react'
import { ItemHeader, ItemLabel } from './styled-components'

interface Props {
  actionButtons?: ReactElement | ReactElement[];
  className?: string;
}

function DevToolItemHeader(props: PropsWithChildren<Props>) {
  const { className, actionButtons, children } = props
  return (
    <ItemHeader className={className}>
      <ItemLabel>
        {children}
      </ItemLabel>
      <div>
        {actionButtons}
      </div>
    </ItemHeader>
  )
}

export { DevToolItemHeader }
