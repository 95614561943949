import React, {useCallback, useMemo} from 'react'
import {useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import icoModalClose from "@/assets/images/ico_modal_close.svg";
import {EducationSurveyData} from "@/network/apis/types";
import {useSelector} from "react-redux";
import {selectMyInfo} from "@/store/my-info";
import {EduSurveyA011} from "@/components/modal/EduSurveyA011";
import {EduSurveyA012} from "@/components/modal/EduSurveyA012";
import {EduSurveyA013} from "@/components/modal/EduSurveyA013";
import {useRegistUserEducationSurvey} from "@/utils/hooks/apis/user/education/survey/use-regist-user-education-survey";

export interface EduSurveyModalProps extends ModalCommonProps {
  eduNo: number;
  data: {
    vo: {
      subject: string;
    };
    surveyQuestionList1: EducationSurveyData[];
    surveyQuestionList2: EducationSurveyData[];
    surveyQuestionList3: EducationSurveyData[];
    surveyQuestionList11: EducationSurveyData[];
    surveyQuestionList12: EducationSurveyData[];
    surveyQuestionList13: EducationSurveyData[];
    surveyQuestionList14: EducationSurveyData[];
    surveyQuestionList21: EducationSurveyData[];
    surveyQuestionList22: EducationSurveyData[];
    surveyQuestionList23: EducationSurveyData[];
  };
  // message: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
}

function EduSurvey(props: EduSurveyModalProps) {
  const { eduNo, data, onOk, onCancel } = props
  const {vo, surveyQuestionList1, surveyQuestionList2, surveyQuestionList3,
    surveyQuestionList11, surveyQuestionList12, surveyQuestionList13, surveyQuestionList14,
    surveyQuestionList21, surveyQuestionList22, surveyQuestionList23
  } = data
  const myInfo = useSelector(selectMyInfo)
  const { closeModal } = useModalManager()
  const registUserEducationSurvey = useRegistUserEducationSurvey()

  const userLevelCd = useMemo(() => {
    return myInfo.userLevelCd
  }, [myInfo.userLevelCd])

  const handleSaveClick = useCallback(async () => {
    let list: EducationSurveyData[] = []
    if(userLevelCd === 'A011') list = [...surveyQuestionList1, ...surveyQuestionList2, ...surveyQuestionList3]
    else if(userLevelCd === 'A012') list = [...surveyQuestionList11, ...surveyQuestionList12, ...surveyQuestionList13, ...surveyQuestionList14]
    else if(userLevelCd === 'A013') list = [...surveyQuestionList21, ...surveyQuestionList22, ...surveyQuestionList23]
    if(list.length){
      const findIndex = list.findIndex((item) => !item.applyAnswer)
      if(findIndex !== -1){
        window.alert(`${findIndex+1}번 설문을 체크해주세요.`)
      }else{
        try {
          const {result} = await registUserEducationSurvey({eduNo, list})
          if(result >= 0){
            closeModal()
            onOk()
          }
        }catch(e){console.log(e)}
      }
    }

  }, [
    userLevelCd,
    registUserEducationSurvey, eduNo,
    surveyQuestionList1, surveyQuestionList2, surveyQuestionList3,
    surveyQuestionList11, surveyQuestionList12, surveyQuestionList13, surveyQuestionList14,
    surveyQuestionList21, surveyQuestionList22, surveyQuestionList23,
    onOk, closeModal,
  ])

  return (
      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-survey'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>{vo.subject} 설문조사</span>
            <button type="button" className={'modal-close-btn'} onClick={() => {
              closeModal()
              onCancel && onCancel()
            }}>
              <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
            </button>
          </div>
          <div className={'modal-content'}>
            <div className={'survey-container'}>
              {userLevelCd === 'A011' && (<EduSurveyA011 list1={surveyQuestionList1} list2={surveyQuestionList2} list3={surveyQuestionList3} />)}
              {userLevelCd === 'A012' && (<EduSurveyA012 list1={surveyQuestionList11} list2={surveyQuestionList12} list3={surveyQuestionList13} list4={surveyQuestionList14} />)}
              {userLevelCd === 'A013' && (<EduSurveyA013 list1={surveyQuestionList21} list2={surveyQuestionList22} list3={surveyQuestionList23} />)}
              <div className={'btn-box type-center'}>
                <button className={'btn type-large type-wide type-apply'} onClick={handleSaveClick}>제출</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export { EduSurvey }
