import Axios from 'axios'
import {useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {BbsData, FileData} from "@/network/apis/types";
import {getUserDataDetail} from "@/network";

interface SearchParams {
    bbsNo: number;
}

const emptyBbsData: BbsData = {
    title: '',
    bbsNo: 0,
    rownum: 0,
    fileYn: '',
    hits: 0,
    contents: '',
    userNm: '',
    deptCd: '',
    nextBbsNo: 0,
    deptNm: '',
    regYmd: '',
    userNo: 0,
}
export function useFetchUserDataDetail(searchParams: SearchParams) {
    const {bbsNo} = searchParams
    const [detail, setDetail] = useState<BbsData>(emptyBbsData)
    const [next, setNext] = useState<BbsData>(emptyBbsData)
    const [prev, setPrev] = useState<BbsData>(emptyBbsData)
    const [files, setFiles] = useState<FileData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserDataDetail({bbsNo}, { cancelToken: canceler.token })
                .then(({data}) => {
                    if(data.result){
                        setDetail(data.result.detail)
                        setNext(data.result.next)
                        setPrev(data.result.prev)
                        setFiles(data.result.files)
                    }else{
                        setDetail(emptyBbsData)
                        setNext(emptyBbsData)
                        setPrev(emptyBbsData)
                        setFiles([])
                    }
                })
                .catch(() => {
                    setDetail(emptyBbsData)
                    setNext(emptyBbsData)
                    setPrev(emptyBbsData)
                    setFiles([])
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, bbsNo])

    return {detail, next, prev, files}
}
