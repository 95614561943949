import {useCallback, useEffect} from 'react';
import {store} from "@/store";
import {selectAppIsMobile, setIsMobile} from "@/store/app";
import {useSelector} from "react-redux";

const maxMobileW = 800
export default function useWindowResizeEvent() {
  const isMobile = useSelector(selectAppIsMobile)
  
  const handleWindowResize = useCallback(() => {
    if(document.body.clientWidth <= maxMobileW){
      !isMobile && store.dispatch(setIsMobile(true))
    }else{
      isMobile && store.dispatch(setIsMobile(false))
    }
  }, [isMobile])
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize()
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [handleWindowResize])
}