import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {ParamRegistEdu} from "@/network/apis/types";

/**
 * 수강내역 수정 API
 * @param params
 * @param config
 */
export function postUserEducationRequestUpdate(params: ParamRegistEdu, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        applyNo,
        occCd,
        deptNm,
        tel,
        hp,
        email,
        applyHour,
        subject,
        eduNo,
        joinNo,
    } = params

    const frm = new FormData()
    frm.append('applyNo', String(applyNo))
    frm.append('occCd', occCd)
    frm.append('deptNm', deptNm)
    frm.append('hp', hp)
    frm.append('email', email)
    frm.append('applyHour', String(applyHour))
    frm.append('eduNo', String(eduNo))
    frm.append('subject', subject)
    frm.append('stayYn', 'N')
    if(tel) frm.append('tel', tel)
    if(joinNo) frm.append('joinNo', String(joinNo))

    return axiosClient.post<ResponseData>('/api/user/education/request/update.do',
        frm,
        config,
    )
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

