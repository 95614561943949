import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 비밀번호 초기화
 * @param params
 * @param config
 */
export function postUserMemberInitPassword(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        userNm,
        birthday,
        hp,
    } = params
    const frm = new FormData()
    frm.append('userNm', userNm)
    frm.append('birthday', birthday)
    frm.append('hp', hp)
    frm.append('authType', '1')

    return axiosClient.post<ResponseData>('/api/uat/uia/initPassword.do',
        frm,
        config,
    )
}

/**
 */
interface Params {
    userNm: string;
    birthday: string;
    hp: string;
}


/**
 * resultCode:              '00': 성공, 'XX': 일치하는 정보 없음, 'ZZ' | etc: 문자 메세지 발송 실패
 */
interface ResponseData {
    resultCode: string;
}

