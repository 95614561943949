import React from 'react'

interface Props {
    className?: string;
    onClick?: () => void;
}
function PdfButton(props: Props) {
    const {className, onClick} = props

    return (
        <button type={'button'} className={className} onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" className={'step-sub-img'} width="40" height="40" viewBox="0 0 40 40">
                <g data-name="그룹 226">
                    <g data-name="그룹 218" transform="translate(-1514 -320)">
                        <circle data-name="타원 24" cx="20" cy="20" r="20" transform="translate(1514 320)" className="ico-color"/>
                    </g>
                    <g data-name="그룹 217">
                        <g data-name="그룹 215">
                            <path data-name="패스 124" d="M820.259 268.738h-11a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h1v-1h-1a1 1 0 1 1 0-2h12v-13a1 1 0 0 0-1-1zm-2 5h-8v-3h8z" transform="translate(-794.259 -257.738)" fill={'#fff'}/>
                            <path data-name="패스 125" d="m823.94 335.291 1.5-1 1.5 1v-4h-3z" transform="translate(-806.94 -305.291)" fill={'#fff'}/>
                            <path data-name="패스 126" d="M840.745 336.461h4.97a1 1 0 0 0 1-1h-5.97z" transform="translate(-819.715 -308.461)" fill={'#fff'}/>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    )
}

export { PdfButton }