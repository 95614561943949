import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {EducationExamData} from "@/network/apis/types";

/**
 * 문제풀이 리스트
 * @param params
 * @param config
 */
export function getUserEducationLectureExamList(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/education/lecture/exam/list.do', {
        ...restConfig,
        params: {
            eduNo,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 교육 번호
 */
interface Params {
    eduNo: number;
}


interface ResponseData {
    list: EducationExamData[];
}

