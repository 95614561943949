import {useMemo} from "react";
import {GrpCd} from "@/network/apis/types";
import {useSelector} from "react-redux";
import {selectCommonCode} from "@/store/code";

interface Props {
    grpCd: GrpCd;
}

export function useCommonCode(props: Props){
    const {grpCd} = props
    const commonCode = useSelector(selectCommonCode)

    return useMemo(() => {
        return commonCode[grpCd]
    }, [grpCd, commonCode])
}
