import { useCallback } from 'react'
import {EduVideo, EduVideoModalProps} from '../EduVideo'
import { useModalManager } from './use-modal-manager'

export function useEduVideoModal() {
  const { showModal } = useModalManager()
  return useCallback((options: EduVideoModalProps) => {
    showModal({
      component: EduVideo,
      props: options as EduVideoModalProps,
    })
  }, [showModal])
}
