import { History } from 'history'
import React, { createContext, memo } from 'react'
import { BrowserRouterProps as NativeBrowserRouterProps, Router } from 'react-router-dom'

interface BrowserRouterProps extends Omit<NativeBrowserRouterProps, 'window'> {
  history: History;
}

/**
 * react-router-dom 6.0.2부터 Router내부에서 사용하는 history인스턴스를 외부에 제공하지 않음.
 * Router의 동작에 지장을 주지 않으면서 popstate이벤트와 모달 닫기를 연동하려면 Router내부에서 사용하는 history인스턴스가 필요함.
 * 앱 전체에서 하나의 history인스턴스를 사용하게 하기위해 직접 만든 BrowserRouter 사용.
 * @see https://github.com/remix-run/react-router/issues/8264
 * @see https://github1s.com/remix-run/react-router/blob/HEAD/packages/react-router-dom/index.tsx
 */
export const BrowserRouter = memo((props: BrowserRouterProps) => {
  const { history, ...restProps } = props
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  })

  React.useLayoutEffect(() => history.listen(setState), [history])

  return (
    <HistoryContext.Provider value={history}>
      <Router {...restProps} location={state.location} navigationType={state.action} navigator={history}/>
    </HistoryContext.Provider>
  )
})

/**
 * browser-router 디렉토리 밖에서 사용 금지.
 */
export const HistoryContext = createContext({} as History)
