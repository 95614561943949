import React, {useCallback, useMemo} from 'react'
import {EducationLectureData} from "@/network/apis/types";
import moment from "moment/moment";
import {useDownloadNote, useHaveNote} from "@/utils/hooks/use-note";
import {PdfButton, PlayButton, QuizButton} from "@/components/button";

interface Props {
  data: EducationLectureData;
  index: number;
  subIndex: number;
  onPlayBtnClick: (data: EducationLectureData) => void;
  onQuizBtnClick: (data: EducationLectureData) => void;
}

export default function EduCourseSubItem(props: Props) {
  const {data, index, subIndex, onPlayBtnClick, onQuizBtnClick} = props
  const {haveNote} = useHaveNote({weekNo: data.weekNo})
  const downloadNote = useDownloadNote()

  const title = useMemo(() => {
    return `${index+1}-${subIndex+1}. ${data.weekTitle}`
  }, [index, subIndex, data.weekTitle])

  const complete = useMemo(() => {
    return `${data.viewYmd ? `완료일: ${moment(data.viewYmd).format('YYYY.MM.DD')}` : ''}`
  }, [data.viewYmd])

  const showQuizBtn = useMemo(() => {
    return data.viewYmd && data.quizCnt > 0 && data.quizCnt > data.answerCnt
  }, [data.viewYmd, data.quizCnt, data.answerCnt])

  const handleDownloadPdfClick = useCallback(() => {
    downloadNote({weekNo: data.weekNo})
  }, [downloadNote, data.weekNo])

  return (
      <div className={`step-sub-row ${data.viewYmd ? 'type-finished' : ''}`}>
        <div className={'step-inner type-sub'}>
          <div className={'step-sub-content'}>
            <div className={'step-text'}>
              <div className={'step-sub-title'}>{title}</div>
              <div className={'step-sub-date'}>{complete}</div>
            </div>
          </div>
          <div className={'step-btn-box'}>
            {haveNote && (<PdfButton className={`step-sub-btn type-on`} onClick={handleDownloadPdfClick} />)}
            {showQuizBtn && (<QuizButton className={`step-sub-btn type-on`} onClick={() => onQuizBtnClick(data)} />)}
            <PlayButton className={`step-sub-btn ${data.viewYmd ? '' : 'type-on'}`} onClick={() => onPlayBtnClick(data)} />
          </div>
        </div>
      </div>
  )
}
