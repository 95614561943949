import React, {useMemo} from 'react'
import {EducationLectureData} from "@/network/apis/types";
import EduCourseGroupItem from "./EduCourseGroupItem";
import EduCourseSubItem from "./EduCourseSubItem";

interface Props {
    list: EducationLectureData[];
    index: number;
    onPlayBtnClick: (data: EducationLectureData) => void;
    onQuizBtnClick: (data: EducationLectureData) => void;
}

export default function EduCourseItem(props: Props) {
    const {list, index, onPlayBtnClick, onQuizBtnClick} = props

    const groupItem = useMemo(() => {
        return list[0]
    }, [list])

    const subItems = useMemo(() => {
        return list.length > 1 ? list.slice(1, list.length) : []
    }, [list])

    const finished = useMemo(() => {
        return groupItem.isGroup === 'Y' ? groupItem.finished >= groupItem.csWeekCnt : groupItem.viewYmd
    }, [groupItem])

    return (
        <div className={`step ${finished ? 'type-finished' : ''}`}>
            <EduCourseGroupItem data={groupItem} index={index} onPlayBtnClick={onPlayBtnClick} onQuizBtnClick={onQuizBtnClick} />
            <div className={'step-sub-box'}>
                {
                    subItems && subItems.map((item, subIndex) => {
                        return (
                            <EduCourseSubItem
                                key={`edu-course-sub-item-${index}-${subIndex}`}
                                data={item} index={index} subIndex={subIndex} onPlayBtnClick={onPlayBtnClick} onQuizBtnClick={onQuizBtnClick} />
                        )
                    })
                }
            </div>
        </div>
    )
}
