import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {EducationData} from "@/network/apis/types";

/**
 * 진행중인(신청가능한) 강의 상세 <수정 가능한 상태일 때 호출>
 * @param params
 * @param config
 */
export function getUserEducationDetail2(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/education/detail2.do', {
        ...restConfig,
        params: {
            eduNo,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * eduNo: 교육 번호
 */
interface Params {
    eduNo: number;
}


interface ResponseData {
    result: EducationData;
}

