import React, { ForwardedRef, forwardRef, ReactElement, ReactNode, RefObject } from 'react'
import { DevToolCopyButton } from './DevToolCopyButton'
import { DevToolItemContent } from './DevToolItemContent'
import { DevToolItemHeader } from './DevToolItemHeader'
import { Row } from './styled-components'

interface Props<E extends HTMLElement> {
  label: string;
  copyContentRef?: RefObject<E>;
  actionButtons?: ReactElement;
  children: ReactNode;
}

const DevToolItem = forwardRef(function DevToolItem<E extends HTMLElement>(props: Props<E>, ref: ForwardedRef<HTMLLIElement>) {
  const { label, actionButtons, copyContentRef, children } = props

  return (
    <Row ref={ref}>
      <DevToolItemHeader
        actionButtons={<>
          {actionButtons}
          {copyContentRef && <DevToolCopyButton contentRef={copyContentRef}/>}
        </>}
      >{label}</DevToolItemHeader>
      <DevToolItemContent>{children}</DevToolItemContent>
    </Row>
  )
})

export { DevToolItem }
