import React from 'react'

interface Props {
    className?: string;
    onClick?: () => void;
}
function QuizButton(props: Props) {
    const {className, onClick} = props

    return (
        <button type={'button'} className={className} onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" className={'step-sub-img'} width="40" height="40" viewBox="0 0 40 40">
                <g data-name="그룹 221" transform="translate(-1514 -320)">
                    <circle data-name="타원 24" cx="20" cy="20" r="20" transform="translate(1514 320)"
                            className="ico-color"/>
                    <g data-name="그룹 216">
                        <g data-name="그룹 214">
                            <path data-name="사각형 222" d="M0 0h10a1 1 0 0 1 1 1 1 1 0 0 1-1 1H0V0z"
                                  transform="rotate(-45 1183.588 -1681.702)" fill={'#fff'}/>
                            <path data-name="패스 122"
                                  d="m838.306 195.91 1.578-.789a.01.01 0 0 0 0-.016l-1.4-1.4a.01.01 0 0 0-.015 0l-.789 1.578a.462.462 0 0 0 .626.627z"
                                  transform="translate(696.618 151.371)" fill={'#fff'}/>
                        </g>
                        <path data-name="패스 123"
                              d="M821.383 141.757V136a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-4.586l-3.906 3.9-1.723.862a1.461 1.461 0 0 1-1.961-1.961l.864-1.726zm-13-4.758h12v1h-12zm0 2.962h12v1h-12zm6 7.038h-6v-1h6zm-6-3.077v-1h9v1z"
                              transform="translate(719.617 196)" fill={'#fff'}/>
                    </g>
                </g>
            </svg>
        </button>
    )
}

export { QuizButton }