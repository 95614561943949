import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {CustomerQnaData, ListResQueryData} from "@/network/apis/types";

/**
 * 고객센터 리스트
 * @param params
 * @param config
 */
export function getUserCustomerQnaList(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        pageIndex,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/customer/qna/list.do', {
        ...restConfig,
        params: {
            pageIndex,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * pageIndex: 페이지 번호
 */
interface Params {
    pageIndex: number;
}


interface ResponseData {
    list: CustomerQnaData[];
    paginationInfo: ListResQueryData;
}