import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {LoginVO, UserLevelCd} from "@/network/apis/types";

type MyInfoState = {
  etcOrgNm?: string;
  loginVO: LoginVO;
  occCd: string;
  orgNo: string;
  userLevelCd: UserLevelCd;
  result: number;
}
type OrgListActionType = {
  data: MyInfoState;
}

interface Actions extends SliceCaseReducers<MyInfoStateItem> {
  setMyInfo(state: MyInfoStateItem, action: PayloadAction<OrgListActionType>): void;
}

interface MyInfoStateItem {
  data: MyInfoState
}

const initialState:MyInfoStateItem = {
  data: {
    etcOrgNm: '',
    loginVO: {
      etcOrgNm: '',
      agreeYn: '',
      authCd: '',
      birthday: '',
      orgNo: '',
      confirmYn: '',
      deptNm: '',
      occCd: '',
      drLicense: '',
      email: '',
      failCnt: 0,
      occNm: '',
      orgNm: '',
      sex: '',
      tel: '',
      logindate: '',
      userLevelCd: 'A011',
      orgTypeCd: '',
      position: '',
      regdate: '',
      userId: '',
      userNm: '',
      userNo: '',
      hp: ''
    },
    result: -1,
    occCd: '',
    orgNo: '',
    userLevelCd: 'A011'
  },
}

export const myInfoSlice = createSlice<MyInfoStateItem, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setMyInfo(state, action) {
      const { payload } = action
      state.data.orgNo = payload.data.orgNo
      state.data.etcOrgNm = payload.data.etcOrgNm
      state.data.result = payload.data.result
      state.data.occCd = payload.data.occCd
      state.data.userLevelCd = payload.data.userLevelCd
      state.data.loginVO = payload.data.loginVO
    },
  },
})

export const {
  setMyInfo,
} = myInfoSlice.actions

export const selectMyInfo = (state: RootState) => state.myInfo.data