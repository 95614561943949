import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 차시 진행중 frame 입력
 * @param params
 * @param config
 */
export function postUserEducationLectureLogFrameUpdate(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        logNo, frame
    } = params

    const frm = new FormData()
    frm.append('logNo', String(logNo))
    frm.append('frame', String(frame))

    return axiosClient.post<ResponseData>('/api/user/education/lecture/log/frame/update.do',
        frm,
        config,
    )
}

interface Params {
    logNo: number;
    frame: number;
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

