import React, {useCallback, useMemo, useState} from 'react'
import { useModalManager } from './hooks'
import { ModalCommonProps } from './types'
import icoModalClose from '@/assets/images/ico_modal_close.svg'
import {EducationData, GrpCd} from "@/network/apis/types";
import moment from "moment/moment";
import {CommonCodeOption, CommonInput} from "@/components";
import {useSelector} from "react-redux";
import {selectMyInfo} from "@/store/my-info";
import {useFetchCommonOrg, useFetchUserKiEduCommonOptionOrg, useRegistUserEducationRequest} from "@/utils";

export interface EduRegistModalProps extends ModalCommonProps {
  // message: ReactNode;
  data: EducationData;
  isEdit: boolean;
  canEdit: boolean;
  onOk: () => void;
  onCancel?: () => void;
}

function EduRegist(props: EduRegistModalProps) {
  const { data, isEdit, canEdit, onOk, onCancel } = props
  const { closeModal } = useModalManager()
  const myInfo = useSelector(selectMyInfo)
  const [occCd, setOccCd] = useState(data.occCd || '')
  const [deptNm, setDeptNm] = useState(data.deptNm || '')
  const [tel, setTel] = useState(data.tel || '')
  const [hp, setHp] = useState(data.hp || '')
  const [email, setEmail] = useState(data.email || '')
  const registUserEducationRequest = useRegistUserEducationRequest()

  const userLevelCd = useMemo(() => myInfo.userLevelCd, [myInfo.userLevelCd])
  const orgNo = useMemo(() => myInfo.orgNo, [myInfo.orgNo])
  const {list: a011OrgList} = useFetchCommonOrg()
  const {list: a013OrgList} = useFetchUserKiEduCommonOptionOrg()
  const orgList = useMemo(() => {
    if(userLevelCd === 'A011') return a011OrgList
    else if(userLevelCd === 'A013') return a013OrgList
    else return []
  }, [a011OrgList, a013OrgList, userLevelCd])
  const selectedOrg = useMemo(() => {
    return orgNo ? orgList.find((item) => item.orgNo === parseInt(orgNo)) : undefined
  }, [orgList, orgNo])
  const occGrpCd = useMemo(() => {
    let grpCd: GrpCd = 'B300'
    switch (userLevelCd){
      case 'A011':
        grpCd = 'B300'
        break
      case 'A012':
        grpCd = 'B500'
        break
      case 'A013':
        if(selectedOrg) grpCd = selectedOrg.orgTypeCd === 'B105' ? 'B600' : 'B300'
        break
    }
    return grpCd
  }, [userLevelCd, selectedOrg])

  const handleSaveClick = useCallback(async () => {
    try{
      const {result} = await registUserEducationRequest({params: {
          eduNo: data.eduNo,
          tel,
          email,
          occCd,
          deptNm,
          hp,
          subject: data.subject,
          applyNo: data.applyNo,
          applyHour: data.hours,
          joinNo: data.joinNo ? data.joinNo : undefined,
        }, isEdit})
      if(result >= 1){
        closeModal()
        onOk()
      }
    }catch(e){console.log(e)}

  }, [registUserEducationRequest, data, tel,
    email, occCd, deptNm, hp, isEdit, onOk, closeModal])

  return (
    <div className="modal-wrap">
      <div className={'modal-inner type-fixed type-regist'}>
        <div className={'model-header'}>
          <span className={'modal-title'}>교육신청 팝업</span>
          <button type="button" className={'modal-close-btn'} onClick={() => {
            closeModal()
            onCancel && onCancel()
          }}>
            <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
          </button>
        </div>
        <div className={'modal-content'}>
          <div className={'regist-box'}>
            <div className={'regist-row'}>
              <div className={'regist-kv'}>
                <div className={'regist-key'}>교육번호</div>
                <div className={'regist-val type-text'}>{data.shortSubject || data.eduNo}</div>
              </div>
              <div className={'regist-kv'}>
                <div className={'regist-key'}>교육구분</div>
                <div className={'regist-val type-text'}>{data.eduTypeNm}</div>
              </div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>교육 훈련명</div>
              <div className={'regist-val type-text'}>{data.subject}</div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>교육기간</div>
              <div className={'regist-val type-text'}>{`${moment(data.startYmd).format('YYYY.MM.DD')}~${moment(data.endYmd).format('YYYY.MM.DD')}`}</div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>온라인 교육기간</div>
              <div className={'regist-val type-text'}>{`${moment(data.onlineStartYmd).format('YYYY.MM.DD')}~${moment(data.onlineEndYmd).format('YYYY.MM.DD')}`}</div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>직종</div>
              <div className={'regist-val'}>
                <CommonCodeOption
                    className={'opt type-modal'}
                    defaultValue={occCd}
                    setValue={setOccCd}
                    grpCd={occGrpCd}
                    showAll={true}
                    showAllTitle={'선택'}
                    disabled={!canEdit}
                />
              </div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>교육신청시간</div>
              <div className={'regist-val type-text'}>{data.hours}시간</div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>부서</div>
              <div className={'regist-val'}>
                <CommonInput className={'tf type-large'} value={deptNm} setValue={setDeptNm} maxLength={30} disabled={!canEdit} />
              </div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>직장전화</div>
              <div className={'regist-val'}>
                <CommonInput type={'hp'} className={'tf type-large'} value={tel} setValue={setTel} disabled={!canEdit} />
              </div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>휴대전화</div>
              <div className={'regist-val'}>
                <CommonInput type={'hp'} className={'tf type-large'} value={hp} setValue={setHp} disabled={!canEdit} />
              </div>
            </div>
            <div className={'regist-row'}>
              <div className={'regist-key'}>이메일주소</div>
              <div className={'regist-val'}>
                <CommonInput className={'tf type-large'} value={email} setValue={setEmail} maxLength={50} disabled={!canEdit} />
              </div>
            </div>
          </div>
        </div>
        <div className={'btn-box type-large type-center type-modal'}>
          <button type="button" className={'btn type-large type-cancel'} onClick={() => {
            closeModal()
            onCancel && onCancel()
          }}>목록</button>
          <button type="button" className={'btn type-large type-apply'} onClick={handleSaveClick}>저장</button>
        </div>
      </div>
    </div>
  )
}

export { EduRegist }
