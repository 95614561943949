import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 메인화면 공지사항 리스트
 * @param params
 * @param config
 */
export function getPublicCheckSession(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/public/checkSession.do', {
        ...restConfig,
        params: {
            currentUrl: window.location.href,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 없음
 */
interface Params {
}

/**
 * result:      1이상 성공, -99: 중복로그인
 */
interface ResponseData {
    result: number;
}

