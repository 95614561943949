import {useMemo} from "react";
import {useSelector} from "react-redux";
import {selectMyInfo} from "@/store/my-info";
import {UserLevelOptions} from "@/components/option/types";

export function useUserLevelNm(){
    const myInfo = useSelector(selectMyInfo)
    const userLevelNm = useMemo(() => {
        return UserLevelOptions[myInfo.userLevelCd]
    }, [myInfo.userLevelCd])
    return {userLevelNm}
}