import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {getUserEducationPassCertiForm} from "@/network";

interface Params {
    searchEduFlag: string;
}

interface ResponseData {
    certificate: {
        skinFlag: string;
    };
}

export function useGetUserEducationPassCertiForm() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: Params) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                getUserEducationPassCertiForm(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        resolve(data)
                    })
                    .catch(() => {
                        reject()
                    })
            )
        })
    }, [prevCanceler, showGlobalLoading])
}
