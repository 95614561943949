import Axios from 'axios'
import {useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {EducationLectureData} from "@/network/apis/types";
import {getUserEducationLectureList} from "@/network";

interface SearchParams {
    eduNo: number;
    reloadCnt: number;
}

export function useFetchUserEducationLectureList(params: SearchParams) {
    const {eduNo, reloadCnt} = params
    const [list, setList] = useState<EducationLectureData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserEducationLectureList({eduNo}, { cancelToken: canceler.token })
                .then(({data}) => {
                    setList(data.list)
                })
                .catch(() => {
                    setList([])
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, eduNo, reloadCnt])

    return {list}
}
