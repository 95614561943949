import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {UserLevelCd} from "@/network/apis/types";

/**
 * 회원정보 수정
 * @param params
 * @param config
 */
export function postUserMemberUpdateUserInfo(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        userId,
        userPw,
        userNm,
        birthday,
        deptNm,
        hp,
        email,
        drLicense,
        occCd,
        orgNo,
        etcOrgNm,
        userLevelCd,
    } = params
    const frm = new FormData()
    frm.append('userId', userId)
    frm.append('userPw', userPw)
    frm.append('userNm', userNm)
    frm.append('birthday', birthday)
    frm.append('sex', '')
    frm.append('deptNm', deptNm)
    frm.append('tel', '')
    frm.append('hp', hp)
    frm.append('email', email)
    frm.append('drLicense', drLicense)
    frm.append('occCd', occCd)
    if(userLevelCd === 'A011' || userLevelCd === 'A013') frm.append('orgNo', orgNo)
    else if(userLevelCd === 'A012') frm.append('etcOrgNm', etcOrgNm)

    return axiosClient.post<ResponseData>('/api/user/member/updateUserInfo.do',
        frm,
        config,
    )
}

/**
 */
interface Params {
    userId: string;
    userPw: string;
    userNm: string;
    birthday: string;
    deptNm: string;
    hp: string;
    email: string;
    drLicense: string;
    occCd: string;
    orgNo: string;
    etcOrgNm: string;
    userLevelCd: UserLevelCd;

}


/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

