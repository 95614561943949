import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import { classNames } from '@/utils';

interface Props {
  className?: string;
  style?: CSSProperties;
}

const Container = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  function Container(props, ref) {
    const { className = '', style } = props;
    
    return (
      <div className={classNames('container', className)} style={style} ref={ref}>
        {props.children}
      </div>
    )
  }
)

export { Container };
