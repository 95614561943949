import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {LoginVO, UserLevelCd} from "@/network/apis/types";

/**
 * 로그인 회원 정보
 * @param params
 * @param config
 */
export function getUserMemberMyInfo(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/member/myInfo.do', {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 없음
 */
interface Params {
}


interface ResponseData {
    etcOrgNm?: string;
    loginVO: LoginVO;
    occCd: string;
    orgNo: string;
    userLevelCd: UserLevelCd;
    result: number;
}

