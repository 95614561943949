import React, {lazy, Suspense, useEffect, useState} from 'react'
import {Container, GlobalLoading, ModalContainer, Nav, useTermsModal} from './components'
import {RoutePath} from "@/pages/routes";
import {Navigate, Route, Routes} from "react-router-dom";
import {useUserLoggedIn} from "@/utils";
import useWindowResizeEvent from "@/utils/hooks/use-window-resize-event";
import {qs} from "url-parse";
import {useSelector} from "react-redux";
import {selectMyInfo, setMyInfo} from "@/store/my-info";
import {getUserMemberMyInfo} from "@/network";
import {store} from "@/store";

const Main = lazy(() => import('@/pages/root/Main'))
const MemberJoin = lazy(() => import('@/pages/member/MemberJoin'))
const MemberInfo = lazy(() => import('@/pages/member/MemberInfo'))
const EduList = lazy(() => import('@/pages/edu/list/EduList'))
const EduCourseList = lazy(() => import('@/pages/edu/course/list/EduCourseList'))
const NoticeList = lazy(() => import('@/pages/notice/list/NoticeList'))
const NoticeDetail = lazy(() => import('@/pages/notice/detail/NoticeDetail'))
const DataList = lazy(() => import('@/pages/data/list/DataList'))
const DataDetail = lazy(() => import('@/pages/data/detail/DataDetail'))
const QnaList = lazy(() => import('@/pages/qna/list/QnaList'))
const QnaDetail = lazy(() => import('@/pages/qna/detail/QnaDetail'))
const QnaWrite = lazy(() => import('@/pages/qna/write/QnaWrite'))
const Error = lazy(() => import('@/pages/error/Error'))
const isLocal = process.env.REACT_APP_ENV === 'local'

function App() {
    const {loggedIn} = useUserLoggedIn()
    const myInfo = useSelector(selectMyInfo)
    useWindowResizeEvent()

    const termsModal = useTermsModal()
    const [isOpenTermsModal, setIsOpenTermsModal] = useState(false)
    useEffect(() => {
        if(myInfo && myInfo.loginVO && myInfo.loginVO.agreeYn === 'N' && !isOpenTermsModal){
            setIsOpenTermsModal(true)
            termsModal({
                onOk: async () => {
                    const {data: myInfo} = await getUserMemberMyInfo({})
                    store.dispatch(setMyInfo({data: myInfo}))
                    setIsOpenTermsModal(false)
                }
            })
        }
    }, [myInfo, termsModal, isOpenTermsModal])

    return (
        <>
            {<GlobalLoading/>}
            <ModalContainer/>
            <Container>
                <Nav />
                <Suspense fallback="">
                    <Routes>
                        <Route path={RoutePath.Main} element={<Main/>}/>
                        {
                            loggedIn ?
                                (
                                    <>
                                        {isLocal && <Route path={RoutePath.MemberJoin} element={<MemberJoin/>}/>}
                                        <Route path={RoutePath.MemberInfo} element={<MemberInfo/>}/>
                                        <Route path={RoutePath.EduList} element={<EduList/>}/>
                                        <Route path={RoutePath.EduCourseList} element={<EduCourseList/>}/>
                                        <Route path={RoutePath.NoticeList} element={<NoticeList/>}/>
                                        <Route path={RoutePath.NoticeDetail} element={<NoticeDetail/>}/>
                                        <Route path={RoutePath.DataList} element={<DataList/>}/>
                                        <Route path={RoutePath.DataDetail} element={<DataDetail/>}/>
                                        <Route path={RoutePath.QnaList} element={<QnaList/>}/>
                                        <Route path={RoutePath.QnaDetail} element={<QnaDetail/>}/>
                                        <Route path={RoutePath.QnaWrite} element={<QnaWrite/>}/>
                                        <Route path={RoutePath.Error} element={<Error/>}/>
                                        <Route path="/" element={<Navigate to={{pathname: RoutePath.Main}} replace />} />
                                        <Route
                                            path="*"
                                            element={<Navigate to={RoutePath.Main} replace />}
                                        />
                                    </>
                                ) :
                                (
                                    <>
                                        <Route path={RoutePath.MemberJoin} element={<MemberJoin/>}/>
                                        <Route path={RoutePath.Error} element={<Error/>}/>
                                        <Route path="/" element={<Navigate to={{pathname: RoutePath.Main}} replace />} />
                                        <Route
                                            path="*"
                                            element={<Navigate to={{pathname: RoutePath.Main, search: qs.stringify({goLogin:true})}} replace />}
                                        />
                                    </>
                                )
                        }
                    </Routes>

                </Suspense>
            </Container>
        </>
    )
}

export default App
