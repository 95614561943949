import { useCallback } from 'react'
import {EduRegist, EduRegistModalProps} from '../EduRegist'
import { useModalManager } from './use-modal-manager'

export function useEduRegistModal() {
  const { showModal } = useModalManager()
  return useCallback((options: EduRegistModalProps) => {
    showModal({
      component: EduRegist,
      props: options as EduRegistModalProps,
    })
  }, [showModal])
}
