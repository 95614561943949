import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {EducationSurveyData} from "@/network/apis/types";

/**
 * 차시 리스트
 * @param params
 * @param config
 */
export function getUserEducationSurveyList(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo,
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/education/survey/list.do', {
        ...restConfig,
        params: {
            eduNo,
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 교육 번호
 */
interface Params {
    eduNo: number;
}


interface ResponseData {
    vo: {
        subject: string;
    }
    surveyQuestionList1: EducationSurveyData[];
    surveyQuestionList2: EducationSurveyData[];
    surveyQuestionList3: EducationSurveyData[];
    surveyQuestionList11: EducationSurveyData[];
    surveyQuestionList12: EducationSurveyData[];
    surveyQuestionList13: EducationSurveyData[];
    surveyQuestionList14: EducationSurveyData[];
    surveyQuestionList21: EducationSurveyData[];
    surveyQuestionList22: EducationSurveyData[];
    surveyQuestionList23: EducationSurveyData[];
}

