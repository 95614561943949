/**
 * modal 디렉토리 밖에서 사용 금지.
 */

import { Dispatch } from 'react'
import { v4 as uuid } from 'uuid'
import { ModalCommonProps, ModalParams } from './types'

const listeners: Dispatch<any>[] = []
const modalList: ModalParams<any>[] = []

function notify(modalList: ModalParams<any>[]) {
  listeners.forEach(dispatch => dispatch(modalList))
}

export function pushModal<Props extends ModalCommonProps>(modalParams: ModalParams<Props>) {
  const { component, props } = modalParams
  const id = uuid()
  modalList.push({
    component,
    props: { ...props, id },
  })
  notify([...modalList])
}

export function popModal() {
  const recentModal = modalList.splice(modalList.length - 1, 1)
  if (recentModal.length > 0) {
    notify([...modalList])
  }
}

export function addModalListListener<T>(listener: Dispatch<T>) {
  listeners.push(listener)
  return () => {
    const index = listeners.findIndex(other => other === listener)
    index !== -1 && listeners.splice(index, 1)
  }
}

export function getModalList() {
  return modalList
}
