import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {CommonOrg} from "@/network/apis/types";

/**
 * KI약품담당자용 기관 리스트
 * @param params
 * @param config
 */
export function getUserKiEduCommonOptionOrg(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        ...restParams
    } = params
    const {
        params: configParams, ...restConfig
    } = config
    return axiosClient.get<ResponseData>('/api/user/ki/edu/common/option/org.do', {
        ...restConfig,
        params: {
            ...restParams,
            ...configParams
        }
    })
}

/**
 * 파라미터 없음
 */
interface Params {
}


interface ResponseData {
    list: CommonOrg[];
}
