import Axios from 'axios'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {CustomerQnaData} from "@/network/apis/types";
import {getUserCustomerQnaList} from "@/network";

interface SearchParams {
    pageIndex: number;
    setFinalPageNo: Dispatch<SetStateAction<number>>;
}
export function useFetchUserCustomerQnaList(searchParams: SearchParams) {
    const {pageIndex, setFinalPageNo} = searchParams
    const [list, setList] = useState<CustomerQnaData[]>([])
    const [totalCnt, setTotalCnt] = useState(0)
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserCustomerQnaList({pageIndex}, { cancelToken: canceler.token })
                .then(({data}) => {
                    const query = data.paginationInfo
                    if(query){
                        setFinalPageNo(query.lastPageNo)
                        setTotalCnt(query.totalRecordCount)
                    }
                    setList(data.list)
                })
                .catch(() => {
                    setList([])
                    setTotalCnt(0)
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, pageIndex, setFinalPageNo])

    return {list, totalCnt}
}
