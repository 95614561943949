import { useCallback } from 'react'
import {Terms, TermsModalProps} from '../Terms'
import { useModalManager } from './use-modal-manager'

export function useTermsModal() {
  const { showModal } = useModalManager()
  return useCallback((options: TermsModalProps) => {
    showModal({
      component: Terms,
      props: options as TermsModalProps,
    })
  }, [showModal])
}
