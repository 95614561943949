import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {ParamEducationSurveyAnswer} from "@/network/apis/types";

/**
 * 설문 정답 입력 API
 * @param params
 * @param config
 */
export function postUserEducationSurveyAnswer(params: ParamEducationSurveyAnswer, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo, list
    } = params

    const frm = new FormData()
    frm.append('eduNo', String(eduNo))
    list.map((item) => {
        frm.append('questionNo', String(item.questionNo))
        frm.append(`answer_${item.questionNo}`, item.applyAnswer || '')
        return false
    })

    return axiosClient.post<ResponseData>('/api/user/education/survey/answer.do',
        frm,
        config,
    )
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

