import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserCustomerQnaInsert} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";

interface ResponseData {
    result: number;
}

export function useRegistUserCustomerQna() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((formData: FormData) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                postUserCustomerQnaInsert(formData, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        const {result} = data
                        if(result >= 1){
                            resolve({result})
                            alert('저장되었습니다.')
                        }else{
                            alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                            reject()
                        }
                    })
                    .catch(() => {
                        alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                        reject()
                    })
            )
        })

    }, [prevCanceler, showGlobalLoading])
}