import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { pushModal } from '../modal-manager'
import { ModalCommonProps, ModalParams } from '../types'

export function useModalManager() {
  const navigate = useNavigate()
  const location = useLocation()
  const locationRef = useRef(location)

  useEffect(() => {
    locationRef.current = location
  }, [location])

  return {
    showModal: useCallback(<Props extends ModalCommonProps>(modalParams: ModalParams<Props>) => {
      pushModal(modalParams)
      navigate(locationRef.current, { state: 'modal'})
    }, [navigate]),
    closeModal: useCallback(() => {
      navigate(-1)
    }, [navigate]),
  }
}
