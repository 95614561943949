import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserMemberInitPassword} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";


interface Params {
    userNm: string;
    birthday: string;
    hp: string;
}

interface ResponseData {
    resultCode: string;
}

export function useRegistUserMemberInitPassword() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: Params) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                postUserMemberInitPassword(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        const {resultCode} = data
                        if(resultCode === "00"){
                            alert("임시 비밀번호를 발송하였습니다. 문자 메세지를 확인 해주세요.")
                            resolve({resultCode})
                        }else if(resultCode === "XX"){
                            alert("일치하는 정보가 없습니다.")
                            reject()
                        }else{
                            // ZZ:서버에러
                            alert("문자 메세지 발송에 실패 했습니다. 다시 시도 해주세요.")
                            reject()
                        }
                    })
                    .catch(() => {
                        alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                        reject()
                    })
            )
        })

    }, [prevCanceler, showGlobalLoading])
}