import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {commonCodeSlice} from "@/store/code";
import {myInfoSlice} from "@/store/my-info";
import {appSlice} from "@/store/app";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    code: commonCodeSlice.reducer,
    myInfo: myInfoSlice.reducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
