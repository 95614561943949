import {useCallback} from 'react';

interface Props {
  userPw: string;
  userPw2: string;
}
export function useVerifyPw() {
  return useCallback((props: Props) => {
    const {userPw, userPw2} = props
    if(userPw){
      let SamePass_0 = 0 //동일문자 카운트
      let SamePass_1 = 0 //연속성(+) 카운드
      let SamePass_2 = 0 //연속성(-) 카운드
      let sameCheckPass = true, checkPass = true
      for(let i = 0; i < userPw.length; i++) {
        const chr_pass_0 = userPw.charAt(i)
        const chr_pass_1 = userPw.charAt(i+1)

        //동일문자 카운트
        if(chr_pass_0 === chr_pass_1) {
          SamePass_0 = SamePass_0 + 1
        }

        const chr_pass_2 = userPw.charAt(i+2)

        //연속성(+) 카운드
        if(chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) === 1 && chr_pass_1.charCodeAt(0) - chr_pass_2.charCodeAt(0) === 1) {
          SamePass_1 = SamePass_1 + 1
        }

        //연속성(-) 카운드
        if(chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) === -1 && chr_pass_1.charCodeAt(0) - chr_pass_2.charCodeAt(0) === -1) {
          SamePass_2 = SamePass_2 + 1
        }
      }

      if(SamePass_0 > 1)  sameCheckPass = false
      if(SamePass_1 > 1 || SamePass_2 > 1 ) checkPass = false

      if(userPw.length < 10 || userPw.length > 20){
        window.alert("비밀번호는 10~20자리를 사용해야 합니다.");
      }else if(!userPw.match(/([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/)){
        window.alert("비밀번호는 영문,숫자,특수문자(!@$%^&* 만 허용)를 사용하여 10~20자까지 가능합니다.");
      }else if(!sameCheckPass){
        window.alert("동일문자를 3번 이상 사용할 수 없습니다.");
      }else if(!checkPass){
        window.alert("연속된 문자열(123 또는 321, abc, cba 등)을\n 3자 이상 사용 할 수 없습니다.");
      }else if(userPw !== userPw2){
        window.alert('비밀번호 확인이 일치하지 않습니다.')
      }else{
        return true
      }
    }
    return false
  }, [])
}