import React, {useCallback, useMemo} from 'react'
import {uniqBy} from "lodash";
import EduCourseItem from "./EduCourseItem";
import {EducationLectureData} from "@/network/apis/types";
import {useEduQuizModal, useEduVideoModal} from "@/components";
import {useGetUserEducationLectureQuizList} from "@/utils";

interface Props {
  type: 'page' | 'modal';
  list: EducationLectureData[];
  className?: string;
  onOk?: () => void;
  onChange?: ({eduNo, weekNo, applyNo, weekUrl, viewYmd}: {
    eduNo: number;
    weekNo: number;
    applyNo: number;
    weekUrl: string;
    viewYmd: string;
  }) => void;
}
function EduCourseContainer(props: Props) {
  const {type, list, className, onOk, onChange} = props
  const eduVideoModal = useEduVideoModal()
  const eduQuizModal = useEduQuizModal()
  const getUserEducationLectureQuizList = useGetUserEducationLectureQuizList()

  const handlePlayBtnClick = useCallback(async (data: EducationLectureData) => {
    if(type === 'page'){
      eduVideoModal({
        eduNo: data.eduNo,
        weekNo: Number(data.weekNo),
        applyNo: Number(data.applyNo),
        weekUrl: data.weekUrl,
        viewYmd: data.viewYmd,
        list,
        onOk: () => {
          onOk && onOk()
        }
      })
    }else if(type === 'modal'){
      onChange && onChange({
        eduNo: data.eduNo,
        weekNo: Number(data.weekNo),
        applyNo: Number(data.applyNo),
        weekUrl: data.weekUrl,
        viewYmd: data.viewYmd,
      })
    }
  }, [type, eduVideoModal, list, onChange, onOk])

  const handleQuizBtnClick = useCallback(async (data: EducationLectureData) => {
    const {list} = await getUserEducationLectureQuizList({eduNo: data.eduNo, weekNo: Number(data.weekNo)})

    eduQuizModal({
      list,
      eduNo: data.eduNo,
      weekNo: Number(data.weekNo),
      onOk: () => {
        onOk && onOk()
      },
    })
  }, [getUserEducationLectureQuizList, eduQuizModal, onOk])

  const renderList = useMemo(() => {
    const uniqList = uniqBy(list, 'csNo')
    return uniqList.map((uniqItem, index) => {
      const filterList = list.filter((item) => item.csNo === uniqItem.csNo)
      return <EduCourseItem key={`edu-course-item-${index}}`}
                            list={filterList}
                            index={index}
                            onPlayBtnClick={handlePlayBtnClick}
                            onQuizBtnClick={handleQuizBtnClick} />
    })
  }, [list, handlePlayBtnClick, handleQuizBtnClick])



  return (
      <div className={`step-box ${className}`}>
        {renderList}
      </div>
  )
}

export { EduCourseContainer }
