import React, {ChangeEvent, CSSProperties, Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react'
import {commaNumber} from "@/utils";

interface Props {
    type?: 'text' | 'number' | 'type-number' | 'hp' | 'textarea' | 'password';
    value: string;
    className?: string;
    style?: CSSProperties;
    maxLength?: number;
    maxNumber?: number;
    disabled?: boolean;
    suffix?: string;
    placeholder?: string;
    setValue: Dispatch<SetStateAction<string>>;
    onEnter?: () => void;
}

const phoneFomatter = (num: string) => {
    let val = num
    if(val.length > 13) val = val.substring(0, 13)
    val = val.replaceAll('-','').replace(/[^0-9]/g,'').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
    return val
}

function CommonInput(props: Props) {
    const {type = 'text', value, className, style, maxLength = 100, maxNumber, disabled, suffix, placeholder, setValue, onEnter} = props
    const [focus, setFocus] = useState(false)

    const renderValue = useMemo(() => {
        return value ? `${(type === 'number' ? commaNumber(value) : value)}${!focus && suffix ? suffix : ''}` : ''
    }, [type, value, focus, suffix])

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let val = e.target.value
        if(type === 'number' || type === 'type-number'){
            const lastStr = val.substring(val.length-1, val.length)
            let num = parseFloat(val.replace(/[^0-9.]/g,'')) || 0
            if((maxNumber || maxNumber === 0) && num > maxNumber) num = maxNumber
            if(num > 999999999999999) num = 999999999999999
            val = String(num)
            if(lastStr === '.' && val.indexOf('.') === -1) val += lastStr
        }else if(type === 'hp'){
            val = phoneFomatter(val)
        }
        setValue(val)
    }, [setValue, maxNumber, type])

    const handleFocus = useCallback(() => {
        setFocus(true)
    }, [])

    const handleBlur = useCallback((e: React.FocusEvent) => {
        let val = (e.target as HTMLInputElement | HTMLTextAreaElement).value
        const lastStr = val.substring(val.length-1, val.length)
        if(lastStr === '.') setValue(val.substring(0, val.length-1))
        setFocus(false)
    }, [setValue])

    const handleKeyDown = useCallback((key: string) => {
        key === 'Enter' && onEnter && onEnter()
    }, [onEnter])

    return (
        <>
            {
                type === 'textarea' ? (
                    <textarea
                           className={className}
                           style={style}
                           maxLength={maxLength}
                           disabled={disabled}
                           value={renderValue}
                           placeholder={placeholder}
                           onChange={handleChange}
                           onFocus={handleFocus}
                           onBlur={handleBlur}
                           onKeyDown={(e) => handleKeyDown(e.key)}
                    />
                ) : (
                    <>
                        <input type={type === 'password' ? 'password' : 'text'}
                               className={className}
                               style={style}
                               maxLength={maxLength}
                               disabled={disabled}
                               value={renderValue}
                               placeholder={placeholder}
                               onChange={handleChange}
                               onFocus={handleFocus}
                               onBlur={handleBlur}
                               onKeyDown={(e) => handleKeyDown(e.key)}
                        />
                    </>

                )
            }
        </>
    )
}

export { CommonInput }