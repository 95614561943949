import Axios from 'axios'
import {useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {EducationClassData} from "@/network/apis/types";
import {getUserEducationPassList} from "@/network";

export function useFetchUserEducationPassList() {
    const [list, setList] = useState<EducationClassData[]>([])
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserEducationPassList({}, { cancelToken: canceler.token })
                .then(({data}) => {
                    setList(data.list)
                })
                .catch(() => {
                    setList([])
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading])

    return {list}
}
