import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";
import {ParamEducationErrorLog} from "@/network/apis/types";

/**
 * 동영상 재생 중 에러 발생 시 로그 입력 API
 * @param params
 * @param config
 */
export function postUserEducationErrorLog(params: ParamEducationErrorLog, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo, fileNm, weekNo, frame
    } = params

    const frm = new FormData()
    frm.append('eduNo', String(eduNo))
    frm.append('fileNm', fileNm)
    frm.append('weekNo', String(weekNo))
    frm.append('frame', String(frame))

    return axiosClient.post<ResponseData>('/api/user/education/error/log.do',
        frm,
        config,
    )
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}

