import { useCallback } from 'react'
import {Login, LoginModalProps} from '../Login'
import { useModalManager } from './use-modal-manager'

export function useLoginModal() {
  const { showModal } = useModalManager()
  return useCallback((options: LoginModalProps) => {
    showModal({
      component: Login,
      props: options as LoginModalProps,
    })
  }, [showModal])
}
