import { useCallback } from 'react'
import {EduSurvey, EduSurveyModalProps} from '../EduSurvey'
import { useModalManager } from './use-modal-manager'

export function useEduSurveyModal() {
  const { showModal } = useModalManager()
  return useCallback((options: EduSurveyModalProps) => {
    showModal({
      component: EduSurvey,
      props: options as EduSurveyModalProps,
    })
  }, [showModal])
}
