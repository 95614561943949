import React, {ChangeEvent, Dispatch, SetStateAction, useCallback} from 'react'

interface Props {
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
    multiple?: boolean;
}

function CommonFile(props: Props) {
    const {files, setFiles, multiple = true} = props
    const id = `common-file-${new Date().getTime()}`

    const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newFiles = e.currentTarget.files as FileList
        if(multiple){
            const arr = [...files]
            for(let i = 0; i < newFiles.length; i++){
                const newFile = newFiles[i]
                arr.findIndex((file) => file.name === newFile.name && file.size === newFile.size) === -1 && arr.push(newFile)
            }
            setFiles(arr)
        }else{
            const arr:File[] = []
            newFiles.length && arr.push(newFiles[0])
            setFiles(arr)
        }
    }, [multiple, files, setFiles])

    const handleFileDeleteClick = useCallback((index: number) => {
        files.splice(index, 1)
        setFiles([...files])
    }, [files, setFiles])

    return (
        <div className={'filebox'}>
            <label htmlFor={id} className="btn filebox_1_label">찾아보기</label>
            <input type="file" id={id} className="upload-hidden" name="common-input" multiple={multiple} onChange={handleFileChange} />
            <div className={'agency-box'}>
                {
                    files && files.map((item, index) => {
                        const key = `file-item-${index}`
                        return (
                            <div key={key} className={'agency-item'} onClick={() => handleFileDeleteClick(index)}>{item.name}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export { CommonFile }