import React, {useCallback, useState} from 'react'
import {useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import icoModalClose from "@/assets/images/ico_modal_close.svg";
import imgLogo from "@/assets/images/logo@3x.png";
import imgLogoSummary from "@/assets/images/logo_summary@3x.png";
import {CommonInput} from "@/components";
import {useRegistUserMemberInitPassword} from "@/utils";

export interface FindIdPwModalProps extends ModalCommonProps {
  // message: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
}

function FindIdPw(props: FindIdPwModalProps) {
  const { /*message, onOk, */onCancel } = props
  const { closeModal } = useModalManager()
  const [userNm, setUserNm] = useState('')
  const [birthday, setBirthday] = useState('')
  const [hp, setHp] = useState('')
  const registUserMemberInitPassword = useRegistUserMemberInitPassword()

  const handleFineIdClick = useCallback(async () => {
    try{
      const {resultCode} = await registUserMemberInitPassword({userNm, birthday, hp})
      if(resultCode === '00'){
        closeModal()
      }
    }catch(e){console.log(e)}

  }, [userNm, birthday, hp, registUserMemberInitPassword, closeModal])

  return (

      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-login'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>아이디/비밀번호 찾기</span>
            <button type="button" className={'modal-close-btn'} onClick={() => {
              closeModal()
              onCancel && onCancel()
            }}>
              <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
            </button>
          </div>
          <div className={'modal-content'}>
            <div className={'login-container'}>
              <div className={'login-logo-box'}>
                <img alt="remis" src={imgLogo} />
                <img alt="remis" src={imgLogoSummary} />
              </div>
              <div className={'login-box'}>
                <div className={'login-row'}>
                  <div className={'login-key'}>이름</div>
                  <div className={'login-val'}>
                    <CommonInput className={'tf type-wide'} value={userNm} setValue={setUserNm} />
                  </div>
                </div>
                <div className={'login-row'}>
                  <div className={'login-key'}>생년월일</div>
                  <div className={'login-val'}>
                    <CommonInput className={'tf type-wide'} maxLength={6} value={birthday} setValue={setBirthday} placeholder={'예) 810101'} />
                  </div>
                </div>
                <div className={'login-row'}>
                  <div className={'login-key'}>휴대전화번호</div>
                  <div className={'login-val'}>
                    <CommonInput type={'hp'} className={'tf type-wide'} value={hp} setValue={setHp} />
                  </div>
                </div>
              </div>
              <div className={'btn-box type-center type-login'}>
                <button type={'button'} className={'btn type-large type-apply type-login'} onClick={handleFineIdClick}>확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export { FindIdPw }
