import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 고객센터 등록
 * @param params
 * @param config
 */
export function postUserCustomerQnaInsert(formData: FormData, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const { params: configParams, ...restConfig } = config
    return axiosClient.post<ResponseData>('/api/user/customer/qna/insert.do',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...restConfig,
        },
    )
}

/**
 * result:              1 이상 성공
 */
interface ResponseData {
    result: number;
}
