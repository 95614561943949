import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {postUserActionLogin} from "@/network";
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {UserLevelCd} from "@/network/apis/types";

interface ResponseData {
    result: number;
}

export function useRegistUserActionLogin() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: {
        userId: string;
        userPw: string;
        userLevelCd: UserLevelCd;
    }) => {
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                postUserActionLogin(params, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        const {result} = data
                        if(result >= 1){
                            resolve({result})
                        }else if(result === 0){
                            alert('관리자 승인 후 로그인이 가능합니다.\r\n관리자에게 문의 하세요.')
                            reject()
                        }else if(result === -1){
                            alert('정보가 일치하지 않습니다.\r\n로그인 정보를 다시 확인해 주세요.')
                            reject()
                        }else if(result === -2){
                            alert('정보가 일치하지 않습니다.\r\n로그인 정보를 다시 확인해 주세요.')
                            reject()
                        } else if(result === -3){
                            alert('5회이상 로그인 실패 하셨습니다.\r\n관리자에게 문의 하세요.')
                            reject()
                        }else{
                            alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                            reject()
                        }
                    })
                    .catch(() => {
                        alert(process.env.REACT_APP_API_ERROR_MESSAGE)
                        reject()
                    })
            )
        })

    }, [prevCanceler, showGlobalLoading])
}