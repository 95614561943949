import React, {useCallback, useState} from 'react'
import {useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import icoModalClose from "@/assets/images/ico_modal_close.svg";
import {EducationExamData, EducationQuizBaseData, EducationQuizData} from "@/network/apis/types";
import {EduQuizMultiChoiceItem} from "@/components/modal/EduQuizMultiChoiceItem";
import {useRegistUserEducationLectureExam, useRegistUserEducationLectureQuiz} from "@/utils";

export interface EduQuizModalProps extends ModalCommonProps {
  list: EducationQuizBaseData[];
  eduNo: number;
  weekNo?: number;
  onOk: () => void;
  onCancel?: () => void;
}

function EduQuiz(props: EduQuizModalProps) {
  const { list, eduNo, weekNo, onOk, onCancel } = props
  const { closeModal } = useModalManager()
  const [renderList, setRenderList] = useState<EducationQuizBaseData[]>(list)
  const [isWrong, setIsWrong] = useState(weekNo ? true : false) // 문제풀이일 때만 활성화
  const registUserEducationLectureExam = useRegistUserEducationLectureExam()
  const registUserEducationLectureQuiz = useRegistUserEducationLectureQuiz()

  const handleSaveClick = useCallback(async () => {
    const noApplyAnswerIndex = renderList.findIndex((item) => !item.applyAnswer)
    if(noApplyAnswerIndex !== -1){
      window.alert(`${noApplyAnswerIndex+1}번 정답을 체크해주세요.`)
      return
    }

    const wrongAnswerList = renderList.filter((item) => item.applyAnswer !== item.answer)
    if(!isWrong && wrongAnswerList.length){
      window.alert("문제가 붉은색으로 표현된 것은 틀린 것 입니다.\r\n검토 후 다시 답안 보내기 버튼을 클릭해 주세요.");
      wrongAnswerList.map((item) => {
        item.wrong = item.applyAnswer
        return false
      })
      setRenderList([...renderList])
      setIsWrong(true)
      return
    }

    try {
      const {result} = await (
          weekNo ?
              registUserEducationLectureQuiz({eduNo, weekNo, list: renderList as EducationQuizData[]}) :
              registUserEducationLectureExam({eduNo, list: renderList as EducationExamData[]})
      )
      if(result >= 0){
        closeModal()
        onOk()
      }
    }catch(e){console.log(e)}
  }, [renderList, isWrong, eduNo, weekNo,
    registUserEducationLectureExam,
    registUserEducationLectureQuiz,
    onOk, closeModal])

  return (
      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-quiz'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>퀴즈</span>
            <button type="button" className={'modal-close-btn'} onClick={() => {
              closeModal()
              onCancel && onCancel()
            }}>
              <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
            </button>
          </div>
          <div className={'modal-content'}>
            <div className={'quiz-box'}>
              {
                  renderList && renderList.map((item, index) => {
                    const key = `edu-quiz-modal-item-${eduNo}-${index}`
                    return (
                        <EduQuizMultiChoiceItem key={key} data={item} no={index+1} />
                    )
                  })
              }

            </div>
          </div>
          <div className={'btn-box type-center type-modal'}>
            <button type="button" className={'btn type-wide type-apply'} onClick={handleSaveClick}>제출</button>
          </div>
        </div>
      </div>
  )
}

export { EduQuiz }
