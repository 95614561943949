import { useCallback } from 'react'
import {EduQuiz, EduQuizModalProps} from '../EduQuiz'
import { useModalManager } from './use-modal-manager'

export function useEduQuizModal() {
  const { showModal } = useModalManager()
  return useCallback((options: EduQuizModalProps) => {
    showModal({
      component: EduQuiz,
      props: options as EduQuizModalProps,
    })
  }, [showModal])
}
