import Axios from 'axios'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {BbsData} from "@/network/apis/types";
import {getUserNoticeList} from "@/network";

interface SearchParams {
    pageIndex: number;
    searchCondition?: '1' | '2';
    searchKeyword?: string;
    setFinalPageNo: Dispatch<SetStateAction<number>>;
}
export function useFetchUserNoticeList(searchParams: SearchParams) {
    const {pageIndex, searchCondition, searchKeyword, setFinalPageNo} = searchParams
    const [list, setList] = useState<BbsData[]>([])
    const [notiList, setNotiList] = useState<BbsData[]>([])
    const [totalCnt, setTotalCnt] = useState(0)
    const showGlobalLoading = useShowGlobalLoading()

    useEffect(() => {
        const canceler = Axios.CancelToken.source()
        showGlobalLoading(
            getUserNoticeList({pageIndex, searchCondition, searchKeyword}, { cancelToken: canceler.token })
                .then(({data}) => {
                    const query = data.paginationInfo
                    if(query){
                        setFinalPageNo(query.lastPageNo)
                        setTotalCnt(query.totalRecordCount)
                    }
                    setList(data.list)
                    setNotiList(data.notiList)
                })
                .catch(() => {
                    setList([])
                    setNotiList([])
                    setTotalCnt(0)
                })
        )
        return () => {
            canceler.cancel()
        }
    }, [showGlobalLoading, pageIndex, searchCondition, searchKeyword, setFinalPageNo])

    return {list, notiList, totalCnt}
}
