import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { RootState } from '../index'
import {GrpCd} from "@/network/apis/types";
import {CommonCode} from "@/network";

type CommonCodeState = Record<GrpCd, CommonCode[]>
type PayloadActionType = {
  key: GrpCd,
  list: CommonCode[],
}

interface Actions extends SliceCaseReducers<CommonCodeStateItems> {
  setCommonCode(state: CommonCodeStateItems, action: PayloadAction<PayloadActionType>): void;
}

interface CommonCodeStateItems {
  items: Partial<CommonCodeState>
}

const initialState:CommonCodeStateItems = {
  items: {},
}

export const commonCodeSlice = createSlice<CommonCodeStateItems, Actions>({
  name: 'app',
  initialState,
  reducers: {
    setCommonCode(state, action) {
      const { payload } = action
      state.items[payload.key] = payload.list
    },
  },
})

export const {
  setCommonCode,
} = commonCodeSlice.actions

export const selectCommonCode = (state: RootState) => state.code.items