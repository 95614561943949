
export type VideoWeek = '/content/001/1/01_' | '/content/001/2/02_' | '/content/001/3/03_' | '/content/002/4/04_' | '/content/002/5/05_' |
    '/content/002/6/06_' | '/content/003/01/swf/co_01_' | '/content/003/02/swf/co_02_' |
    '/content/004/001/01/swf/co_01_' | '/content/004/001/02/swf/co_02_' | '/content/004/001/03/swf/co_03_' |
    '/content/004/002/01/swf/co_01_' | '/content/004/002/02/swf/co_02_' | '/content/004/002/03/swf/co_03_' |
    '/content/005/01/co_01_' | '/content/005/02/co_02_' | '/content/005/03/co_03_' |
    '/content/006/01/07_' | '/content/006/02/08_' | '/content/006/03/10_' |
    '/content/012/01/012_01_' | '/content/013/01/013_01_' | '/content/014/01/014_01_' |
    '/content/015/01/015_01_' | '/content/016/01/016_01_' | '/content/017/01/017_01_' |
    '/content/018/01/018_01_' | '/content/018/02/018_02_' | '/content/018/03/018_03_' | '/content/018/04/018_04_' |
    '/content/019/01/019_01_' | '/content/020/01/020_01_' | '/content/020/02/020_02_' |
    '/content/021/01/021_01_' | '/content/022/01/022_01_' | '/content/023/01/023_01_' | '/content/024/01/024_01_' |
    '/content/025/01/025_01_' | '/content/026/01/026_01_' | '/content/027/01/027_01_' | '/content/028/01/028_01_' |
    '/content/029/01/029_01_' | '/content/030/01/030_01_' | '/content/031/01/031_01_' | '/content/031/02/031_02_' |
    '/content/032/01/032_01_' | '/content/033/01/033_01_' | '/content/034/01/034_01_' | '/content/035/01/035_01_' |
    '/content/036/01/036_01_' | '/content/037/01/037_01_' |
    '/content/038/01/038_01_' | '/content/038/02/038_02_' | '/content/038/03/038_03_' | '/content/038/04/038_04_' |
    '/content/039/01/039_01_' | '/content/040/01/040_01_' |
    '/content/041/01/041_01_' | '/content/041/02/041_02_' |
    '/content/042/01/042_01_' | '/content/042/02/042_02_' | '/content/042/03/042_03_' |
    '/content/043/01/043_01_' | '/content/043/02/043_02_' | '/content/043/03/043_03_' | '/content/043/04/043_04_' |
    '/content/044/01/044_01_' | '/content/044/02/044_02_' |
    '/content/045/01/045_01_' | '/content/045/02/045_02_'


export const videoWeekData: Record<VideoWeek, {len: number; title: string; subtitles?: boolean; fillZero?: boolean;}> = {
  '/content/001/1/01_':{'len':10, 'title':'1. 기초 방사선방호의 이해', subtitles: true},
  '/content/001/2/02_':{'len':8, 'title':'2. 기초 방사선방호의 이해', subtitles: true},
  '/content/001/3/03_':{'len':12, 'title':'3. 기초 방사선방호의 이해', subtitles: true},
  '/content/002/4/04_':{'len':8, 'title':'1. 방사는 사고시 개인방호 및 응급제염', subtitles: true},
  '/content/002/5/05_':{'len':9, 'title':'2. 초동대응요원이 알아야 할 현장기본원칙', subtitles: true},
  '/content/002/6/06_':{'len':8, 'title':'3. 방호장비의 착의 및 탈의와 현장에서의 기본원칙', subtitles: true},
  '/content/003/01/swf/co_01_':{'len':7, 'title':'1. 원자력시설 등의 방호 및 방사능 방재 대책법', fillZero: true},
  '/content/003/02/swf/co_02_':{'len':11, 'title':'2. 원자력시설 등의 방호 및 방사능 방재 대책법', fillZero: true},
    '/content/004/001/01/swf/co_01_':{'len':6, 'title':'1. 방사선의 개념, 측정원리 및 측정 장비', subtitles: true, fillZero: true},
  '/content/004/001/02/swf/co_02_':{'len':6, 'title':'2. 표면오염도 계산 과정과 피폭선량평가', subtitles: true, fillZero: true},
  '/content/004/001/03/swf/co_03_':{'len':8, 'title':'3. 방사선 측정 실기', subtitles: true, fillZero: true},
  '/content/004/002/01/swf/co_01_':{'len':5, 'title':'1. 방사선 사고의 특성 및 방사능 표지와 장치', subtitles: true, fillZero: true},
  '/content/004/002/02/swf/co_02_':{'len':6, 'title':'2. 사고 시 현장 대응 요령', subtitles: true, fillZero: true},
  '/content/004/002/03/swf/co_03_':{'len':5, 'title':'3. 세부 대응 지침', subtitles: true, fillZero: true},
  '/content/005/01/co_01_':{'len':6, 'title':'1. 방사선이 인체에 미치는 영향', subtitles: true, fillZero: true},
  '/content/005/02/co_02_':{'len':4, 'title':'2. 결정론적 영향', subtitles: true, fillZero: true},
  '/content/005/03/co_03_':{'len':4, 'title':'3. 확률론적 영향', subtitles: true, fillZero: true},
  '/content/006/01/07_':{'len':4, 'title':'1. 개인보호장구 착탈의 방법'},
  '/content/006/02/08_':{'len':8, 'title':'2. 방사선 사고 시 병원대응 및 제염 절차'},
  '/content/006/03/10_':{'len':3, 'title':'3. 전신 습식 제염 및 자가 습식 제염'},
  //2020-10 추가 동영상
  '/content/012/01/012_01_':{'len':1, 'title':'국가방능방재체계 및 국가방사선비상진료센터 소개 '},
  '/content/013/01/013_01_':{'len':1, 'title':'저선량 방사선의 인체영향'},
  '/content/014/01/014_01_':{'len':1, 'title':'물리적 선량평가'},
  '/content/015/01/015_01_':{'len':3, 'title':'계측장비 설치 및 운용방법'},
  '/content/016/01/016_01_':{'len':1, 'title':'생물학적 선량평가'},
  '/content/017/01/017_01_':{'len':1, 'title':'고선량 방사선의 인체영향'},
  '/content/018/01/018_01_':{'len':1, 'title':'현장방사선비상진료소 설치 및 운영 - 이론'},
  '/content/018/02/018_02_':{'len':3, 'title':'전개형텐트'},
  '/content/018/03/018_03_':{'len':3, 'title':'제염텐트'},
  '/content/018/04/018_04_':{'len':1, 'title':'방사능분진확산방지장치(음압텐트)'},
  '/content/019/01/019_01_':{'len':1, 'title':'방사선방호약품의 개념 및 관리방법'},
  '/content/020/01/020_01_':{'len':1, 'title':'방사선 사고사례 1'},
  '/content/020/02/020_02_':{'len':1, 'title':'방사선 사고사례 2'},
  '/content/021/01/021_01_':{'len':1, 'title':'방사능 오염환자의 처치'},
  '/content/022/01/022_01_':{'len':1, 'title':'방사선비상진료 통신 및 기록'},
  '/content/023/01/023_01_':{'len':1, 'title':'방사선복합손상상해자의 중증도 분류'},
  '/content/024/01/024_01_':{'len':1, 'title':'화생방 상황에 대한 초동대응을 위한 고려사항'},

  //2021-10 추가 동영상(초동)
  '/content/025/01/025_01_':{'len':2, 'title':'병원 전 외상 처치의 황금 원칙'},
  '/content/026/01/026_01_':{'len':4, 'title':'초동대응단계에서의 제독 제염'},
  '/content/027/01/027_01_':{'len':2, 'title':'병원 전 처치의 과학 윤리 비판적사고'},
  '/content/028/01/028_01_':{'len':2, 'title':'현장평가'},

  //2021-11 추가 동영상(성우)
  '/content/029/01/029_01_':{'len':1, 'title':'국가방능방재체계 및 국가방사선비상진료센터 소개 '},
  '/content/030/01/030_01_':{'len':1, 'title':'화생방 상황에 대한 초동대응을 위한 고려사항'},
  '/content/031/01/031_01_':{'len':1, 'title':'방사선 사고사례 1'},
  '/content/031/02/031_02_':{'len':1, 'title':'방사선 사고사례 2'},
  '/content/032/01/032_01_':{'len':1, 'title':'방사능 오염환자의 처치'},
  '/content/033/01/033_01_':{'len':1, 'title':'고선량 방사선의 인체영향'},
  '/content/034/01/034_01_':{'len':1, 'title':'저선량 방사선의 인체영향'},
  '/content/035/01/035_01_':{'len':1, 'title':'방사선방호약품의 개념 및 관리방법'},
  '/content/036/01/036_01_':{'len':1, 'title':'물리적 선량평가'},
  '/content/037/01/037_01_':{'len':1, 'title':'생물학적 선량평가'},
  '/content/038/01/038_01_':{'len':1, 'title':'현장방사선비상진료소 설치 및 운영 - 이론'},
  '/content/038/02/038_02_':{'len':3, 'title':'전개형텐트'},
  '/content/038/03/038_03_':{'len':3, 'title':'제염텐트'},
  '/content/038/04/038_04_':{'len':1, 'title':'방사능분진확산방지장치(음압텐트)'},
  '/content/039/01/039_01_':{'len':1, 'title':'방사선복합손상상해자의 중증도 분류'},
  '/content/040/01/040_01_':{'len':1, 'title':'방사선비상진료 통신 및 기록'},

  //2023-01 추가 동영상(KI교육)
  '/content/041/01/041_01_':{'len':1, 'title':'방사능방재법, 시행령, 시행규칙 개정사항 소개'},
  '/content/041/02/041_02_':{'len':1, 'title':'갑상샘 방호 약품 비축 및 관리와 사전 배포에 관한 지침 제정 소개'},
  '/content/042/01/042_01_':{'len':1, 'title':'원전 사고 사례를 통한 갑상샘 방호 약품의 이해'},
  '/content/042/02/042_02_':{'len':1, 'title':'갑상샘 방호 약품 개요'},
  '/content/042/03/042_03_':{'len':1, 'title':'갑상샘 방호 약품 관리 요령'},
  '/content/043/01/043_01_':{'len':1, 'title':'갑상샘 방호 약품 사전 배포'},
  '/content/043/02/043_02_':{'len':1, 'title':'주민설명회 운영 사전 준비'},
  '/content/043/03/043_03_':{'len':1, 'title':'주민설명회 운영 방법 및 사전 배포 사후관리'},
  '/content/043/04/043_04_':{'len':1, 'title':'갑상샘 방호 약품 사전 배포 대상 및 방법 Q&A'},
  '/content/044/01/044_01_':{'len':1, 'title':'법령 및 지침 요약 소개'},
  '/content/044/02/044_02_':{'len':1, 'title':'갑상샘 방호 약품 복용 시 주의사항 등'},
  '/content/045/01/045_01_':{'len':1, 'title':'갑상샘 방호 약품 사전 배포 주요 사항 요약 소개'},
  '/content/045/02/045_02_':{'len':1, 'title':'주민설명회 운영 시뮬레이션'},
}

export interface VideoType {
  path: VideoWeek;
  cnt: number;
  subtitles: boolean;
}

const videoList: {[Key in string]: VideoType} = {}

for(let key in videoWeekData){
  const castKey = key as VideoWeek
  const weekData = videoWeekData[castKey]
  for(let i = 1; i <= weekData.len; i++){
    const num = weekData.fillZero && i < 10 ? '0'+i : i
    videoList[`${castKey}${num}.html`] = {
      path: castKey,
      cnt: i,
      subtitles: weekData.subtitles ? true : false,
    }
  }
}

export {videoList}