import React from 'react'

interface Props {
    className?: string;
    onClick?: () => void;
}
function PlayButton(props: Props) {
    const {className, onClick} = props

    return (
        <button type={'button'} className={className} onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" className={'step-sub-img type-video'} width="40" height="40" viewBox="0 0 40 40">
                <g data-name="그룹 213">
                    <path data-name="패스 127" d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0z" className="ico-color"/>
                    <path data-name="다각형 1" d="m8 0 8 13.6H0z" transform="rotate(90 8.5 20.5)" fill={'#fff'}/>
                </g>
            </svg>
        </button>
    )
}

export { PlayButton }