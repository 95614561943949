import {AxiosPromise, AxiosRequestConfig} from 'axios'
import {axiosClient} from "@/network";

/**
 * 차시 시작 로그 입력
 * @param params
 * @param config
 */
export function postUserEducationLectureLogInsert(params: Params, config: AxiosRequestConfig = {}): AxiosPromise<ResponseData> {
    const {
        eduNo, weekNo, fileNm, frame
    } = params

    const frm = new FormData()
    frm.append('eduNo', String(eduNo))
    frm.append('weekNo', String(weekNo))
    frm.append('fileNm', fileNm)
    frm.append('frame', String(frame))

    return axiosClient.post<ResponseData>('/api/user/education/lecture/log/insert.do',
        frm,
        config,
    )
}

interface Params {
    eduNo: number;
    weekNo: number;
    fileNm: string;
    frame: number;
}

/**
 * result:              1 이상 성공
 * logNo:               차시 수강 로그 번호
 */
interface ResponseData {
    result: number;
    logNo: number;
}

