import React, { createContext, PropsWithChildren, useContext } from 'react'

export interface WebViewConfig {
  onCopyText?: (text: string) => void;
  onToastMessage?: (text: string) => void;
}

type Props = PropsWithChildren<{
  webViewConfig?: WebViewConfig;
}>

const Context = createContext(null as (WebViewConfig | null))

function WebViewConfigProvider(props: Props) {
  const { webViewConfig = null, children } = props
  return (
    <Context.Provider value={webViewConfig}>
      {children}
    </Context.Provider>
  )
}

function useWebViewConfig() {
  return useContext(Context)
}

export { WebViewConfigProvider, useWebViewConfig }
