import Axios, {CancelTokenSource} from 'axios'
import {useCallback, useState} from 'react'
import {useShowGlobalLoading} from "@/components/loading/hooks";
import {EducationSurveyData} from "@/network/apis/types";
import {getUserEducationSurveyList} from "@/network";

interface SearchParams {
    eduNo: number;
}

interface ResponseData {
    vo: {
        subject: string;
    };
    surveyQuestionList1: EducationSurveyData[];
    surveyQuestionList2: EducationSurveyData[];
    surveyQuestionList3: EducationSurveyData[];
    surveyQuestionList11: EducationSurveyData[];
    surveyQuestionList12: EducationSurveyData[];
    surveyQuestionList13: EducationSurveyData[];
    surveyQuestionList14: EducationSurveyData[];
    surveyQuestionList21: EducationSurveyData[];
    surveyQuestionList22: EducationSurveyData[];
    surveyQuestionList23: EducationSurveyData[];
}

export function useGetUserEducationSurveyList() {
    const [prevCanceler, setCanceler] = useState<CancelTokenSource | null>(null)
    const showGlobalLoading = useShowGlobalLoading()

    return useCallback((params: SearchParams) => {
        const {eduNo} = params
        if(prevCanceler) prevCanceler.cancel()
        const canceler = Axios.CancelToken.source()
        setCanceler(canceler)

        return new Promise<ResponseData>((resolve, reject) => {
            showGlobalLoading(
                getUserEducationSurveyList({eduNo}, { cancelToken: canceler.token })
                    .then( ({data}) => {
                        resolve(data)
                    })
                    .catch(() => {
                        reject()
                    })
            )
        })
    }, [prevCanceler, showGlobalLoading])
}
