import React, {useCallback, useState} from 'react'
import {useFindIdPwModal, useModalManager} from './hooks'
import {ModalCommonProps} from './types'
import icoModalClose from "@/assets/images/ico_modal_close.svg";
import imgLogo from "@/assets/images/logo@3x.png";
import imgLogoSummary from "@/assets/images/logo_summary@3x.png";
import {CommonInput, CommonOption} from "@/components";
import {useRegistUserActionLogin} from "@/utils";
import {UserLevelCd} from "@/network/apis/types";
import {UserLevelOptions} from "@/components/option/types";
import {getUserMemberMyInfo} from "@/network";
import {store} from "@/store";
import {setMyInfo} from "@/store/my-info";
import {useNavigate} from "react-router-dom";
import {RoutePath} from "@/pages/routes";

export interface LoginModalProps extends ModalCommonProps {
  // message: ReactNode;
  onOk: () => void;
  onCancel?: () => void;
}

function Login(props: LoginModalProps) {
  const { /*message, onOk, */onCancel } = props
  const { closeModal } = useModalManager()
  const [userId, setUserId] = useState('')
  const [userPw, setUserPw] = useState('')
  const [userLevelCd, setUserLevelCd] = useState<UserLevelCd | ''>('A011')
  const registUserActionLogin = useRegistUserActionLogin()
  const findIdPwModal = useFindIdPwModal()
  const navigate = useNavigate()

  const handleFindIdPwClick = useCallback(() => {
    findIdPwModal({
      onOk: () => {
        console.log('ok')
      },
    })
  }, [findIdPwModal])

  const handleJoinClick = useCallback(() => {
    navigate(RoutePath.MemberJoin)
    closeModal()
  }, [navigate, closeModal])

  const handleLoginClick = useCallback(async () => {
    if(!userId) alert('아이디를 입력하세요.')
    else if(!userPw) alert('비밀번호를 입력하세요.')
    else if(!userLevelCd) alert('회원 구분을 선택하세요.')
    else{
      try{
        const {result} = await registUserActionLogin({userId, userPw, userLevelCd})
        if(result >= 1){
          closeModal()

          setTimeout(async () => {
            const {data: myInfo} = await getUserMemberMyInfo({})
            store.dispatch(setMyInfo({data: myInfo}))
            navigate({pathname: RoutePath.Main}, {replace: true})
          }, 1)
        }
      }catch(e){console.log(e)}
    }
  }, [userLevelCd, userId, userPw, registUserActionLogin, navigate, closeModal])

  return (

      <div className="modal-wrap">
        <div className={'modal-inner type-fixed type-login'}>
          <div className={'model-header'}>
            <span className={'modal-title'}>로그인</span>
            <button type="button" className={'modal-close-btn'} onClick={() => {
              closeModal()
              onCancel && onCancel()
            }}>
              <img alt="remis" src={icoModalClose} className={'modal-close-ico'} />
            </button>
          </div>
          <div className={'modal-content'}>
            <div className={'login-container'}>
              <div className={'login-logo-box'}>
                <img alt="remis" src={imgLogo} />
                <img alt="remis" src={imgLogoSummary} />
              </div>
              <div className={'login-box'}>
                <div className={'login-row'}>
                  <div className={'login-key'}>아이디</div>
                  <div className={'login-val'}>
                    <CommonInput className={'tf type-wide'} value={userId} setValue={setUserId} maxLength={30} />
                  </div>
                </div>
                <div className={'login-row'}>
                  <div className={'login-key'}>패스워드</div>
                  <div className={'login-val'}>
                    <CommonInput type={'password'} className={'tf type-wide'} value={userPw} setValue={setUserPw} maxLength={20} onEnter={handleLoginClick}/>
                  </div>
                </div>
                <div className={'login-row'}>
                  <div className={'login-key'}>구분</div>
                  <div className={'login-val'}>
                    <CommonOption<UserLevelCd>
                        className={'opt type-popup'}
                        defaultValue={userLevelCd}
                        items={UserLevelOptions}
                        setValue={setUserLevelCd}
                    />
                  </div>
                </div>
              </div>

              <div className={'login-link-box'}>
                <button type={'button'} className={'login-link'} onClick={handleFindIdPwClick}>아이디 / 비밀번호 찾기</button>
                <button type={'button'} className={'login-link'} onClick={handleJoinClick}>회원가입</button>
              </div>
              <div className={'btn-box type-center type-login'}>
                <button type={'button'} className={'btn type-large type-apply type-login'} onClick={handleLoginClick}>로그인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export { Login }
