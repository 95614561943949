import React from 'react'
import {EducationSurveyData} from "@/network/apis/types";
import {EduSurveyMultiChoiceItem} from "./EduSurveyMultiChoiceItem";
import {EduSurveyShortAnsItem} from "./EduSurveyShortAnsItem";

interface Props {
    list1: EducationSurveyData[];
    list2: EducationSurveyData[];
    list3: EducationSurveyData[];
}

function EduSurveyA013(props: Props) {
    const { list1, list2, list3 } = props

    return (
        <>
            <div className={'survey-preface'}>
                <div className={'preface-title'}>교육 후 설문</div>
                <div className={'preface-text'}>
                    이번 교육과정의 만족도 평가입니다. 아래 문항들을 읽고 생각하시는 정도에 따라 오른쪽 란에 체크하여 주십시오.
                </div>
                <div className={'preface-eg-box'}>
                    <div className={'preface-eg-item type-title'}>응답요령</div>
                    <div className={'preface-eg-item'}>①<br />전혀 그렇지 않다</div>
                    <div className={'preface-eg-item'}>②<br />그렇지 않다</div>
                    <div className={'preface-eg-item'}>③<br />보통이다</div>
                    <div className={'preface-eg-item'}>④<br />그렇다</div>
                    <div className={'preface-eg-item'}>⑤<br />매우 그렇다</div>
                </div>
            </div>
            <div className={'survey-box'}>
                {
                    list1 && list1.map((item, index) => {
                        return (
                            <EduSurveyMultiChoiceItem key={`survey-modal-item1-${item.questionNo}-${index}`}
                                                      data={item} no={index+1}
                            />
                        )
                    })
                }
            </div>
            <div className={'survey-preface'}>
                <div className={'preface-title'}>강의별 평가</div>
                <div className={'preface-text'}>
                    이번 교육과정에서 강의한 아래 교과목 별 만족도 평가입니다. 아래 문항들을 읽고 생각하시는 정도에 따라 오른쪽 란에 체크하여 주십니다.
                </div>
                <div className={'preface-eg-box'}>
                    <div className={'preface-eg-item type-title'}>응답요령</div>
                    <div className={'preface-eg-item'}>①<br />매우 불만족</div>
                    <div className={'preface-eg-item'}>②<br />불만족</div>
                    <div className={'preface-eg-item'}>③<br />보통</div>
                    <div className={'preface-eg-item'}>④<br />만족</div>
                    <div className={'preface-eg-item'}>⑤<br />매우 만족</div>
                </div>
            </div>
            <div className={'survey-box'}>
                {
                    list2 && list2.map((item, index) => {
                        return (
                            <EduSurveyMultiChoiceItem key={`survey-modal-item2-${item.questionNo}-${index}`}
                                                      data={item} no={list1.length+index+1}
                            />
                        )
                    })
                }
            </div>
            <div className={'survey-preface'}>
                <div className={'preface-title'}>주관식 설문</div>
                <div className={'preface-text'}>
                    다음 질문의 답변을 기입하여 주시기 바랍니다.
                </div>
            </div>
            <div className={'survey-box'}>
                {
                    list3 && list3.map((item, index) => {
                        return (
                            <EduSurveyShortAnsItem key={`survey-modal-item3-${item.questionNo}-${index}`}
                                                      data={item} no={list1.length+list2.length+index+1}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export { EduSurveyA013 }
